<div
	class="mat-custom-input"
	ngDefaultControl
	[formGroup]="form"
	[class.hide-hint]="hideHint"
	[class.input-valid]="form.controls[name].value">
	<h1 *ngIf="bigLabel">
		{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</h1>
	<label *ngIf="!bigLabel" class="mat-body-1" [for]="inputId"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></label
	>

	<mat-form-field [id]="inputId" ngDefaultControl>
		<input
			[id]="inputId"
			[type]="type"
			matInput
			[formControlName]="name"
			[errorStateMatcher]="errorMatcher"
			[placeholder]="placeholder"
			(focus)="focusInput()"
			(keypress)="onKeyDown($event)" />
		<mat-hint>{{ assistiveText }}</mat-hint>
		<mat-error *ngIf="errorMessage !== ''">{{ errorMessage }}</mat-error>
	</mat-form-field>
</div>
