/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Component,
	Input,
	OnInit,
	ChangeDetectorRef
} from '@angular/core';
import {
	FormGroup,
	FormControl,
	FormGroupDirective,
	NgForm,
	ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
	AttachmentType,
	DependsOn,
	ExtraOption,
	Field,
} from 'src/app/types/fields';
import { FileUploadService } from '@services/file-upload.service';
import { ExtendedFileModel } from '@models/file.model';
import { HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';
import {
	catchError,
	debounceTime,
	tap,
	distinctUntilChanged,
} from 'rxjs/operators';
import { SurveyStore } from 'src/app/stores/survey';
import { ActivatedRoute } from '@angular/router';
import { isEmpty, toNumber } from 'lodash';
import { LIMITER } from 'src/app/constants/team';
import { DEBOUNCE_TIME } from 'src/app/constants/commons';
import { extendedValidator } from 'src/app/utils/form';
import { SnackBarService } from '@services/snack-bar.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}

export class MyGroupErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormGroup | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}
@Component({
	selector: 'app-dynamic-field-component',
	templateUrl: './dynamic-field-component.component.html',
	styleUrls: ['./dynamic-field-component.component.scss'],
})
export class DynamicFieldComponentComponent implements OnInit {
	@Input() index!: number;
	@Input() field!: Field;
	@Input() fields: Field[] = [];
	@Input() form!: FormGroup;
	@Input() memberId!: string | number | undefined;
	@Input() programId!: string | number | undefined;
	matcher = new MyErrorStateMatcher();
	checkboxMatcher = new MyGroupErrorStateMatcher();
	// Upload Component Params
	lastIndexFile = 0;
	uploadList: ExtendedFileModel[] = [];
	// Show Radio Inline Options
	isFirstRadioInline = false;
	isFirstDynamicFieldInline = false;
	isFirstTeamRoleCountInline = false;
	nextRadioInputMix!: Field;
	showInputForLinkedQuestionValue = true;
	isFirstLoadForBed = true;

	constructor(
		private fileUploadService: FileUploadService,
		private activeRoute: ActivatedRoute,
		private store: SurveyStore,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		const activeRoute = this.activeRoute.snapshot.params;
		// Call showInputForLinkedQuestion on OnInit to avoid infinite calls
		this.showInputForLinkedQuestionValue = this.showInputForLinkedQuestion();
		// Validations to render radio inputs
		if (
			this.field.type === 'radio' &&
			(this.field.style === 'inline' || this.field.style === 'inline-input')
		) {
			if (this.field.style === 'inline-input') {
				if (this.fields[this.index + 1].type === 'number') {
					this.nextRadioInputMix = this.fields[this.index + 1];
					// Detect changes within inline input component
					this.form
						.get(this.nextRadioInputMix.name)
						?.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
						.subscribe(value => {
							if (
								(activeRoute['entryType'] === 'Hospital' ||
									activeRoute['entryType'] === 'Program') &&
								this.form.get(this.field.name)?.valid &&
								!this.isFirstLoadForBed
							) {
								if (value === '' || toNumber(value) >= 0) {
									this.store
										.saveEntryChange(
											activeRoute['hospitalId'],
											activeRoute['itemId'],
											this.nextRadioInputMix.name,
											value !== '' ? toNumber(value) : null
										)
										.subscribe({
											error: () => {
												this.store.showErrorMessage({});
											},
										});
								} else {
									this.form.get(this.nextRadioInputMix.name)?.setValue(null);
								}
							}

							this.isFirstLoadForBed = false;
						});
				}
			}

			if (
				this.fields[this.index - 1].type === 'paragraph' &&
				this.fields[this.index + 1].type === 'radio' &&
				this.fields[this.index + 1].style === 'inline'
			) {
				this.isFirstRadioInline = true;
			}

			if (
				this.fields[this.index - 1].type === 'paragraph' &&
				this.fields[this.index + 1].type === 'number'
			) {
				this.isFirstRadioInline = true;
			}
		}

		// For Show Header in Payor Component
		if (this.field.type === 'checkbox' && this.field.style === 'inline') {
			if (
				this.fields[this.index - 1].type === 'paragraph' &&
				this.fields[this.index + 1].type === 'checkbox' &&
				this.fields[this.index + 1].style === 'inline'
			) {
				this.isFirstDynamicFieldInline = true;
			}
		}

		// For Show Header in Team Role Count Component
		if (this.field.type === 'fields' && this.field.style === 'inline') {
			if (
				this.fields[this.index - 1].type === 'paragraph' &&
				this.fields[this.index + 1].type === 'fields' &&
				this.fields[this.index + 1].style === 'inline'
			) {
				this.isFirstTeamRoleCountInline = true;
			}
		}

		if (this.field.type === 'file' && this.field.attachments) {
			this.field.attachments?.forEach((item: AttachmentType) => {
				this.uploadList.push({
					file: {
						name: item.name,
						size: item.fileSize,
					},
					uploadUrl: `Attachment?hospitalId=${
						activeRoute['hospitalId']
					}&entityId=${activeRoute['itemId']}&entityType=${activeRoute[
						'entryType'
					].toUpperCase()}&year=${activeRoute['year']}&entityField=${
						this.field.name
					}`,
					uploadStatus: {
						isSucess: true,
						previewUrl: item.location,
						attachmentID: item.id.toString(),
					},
				});
			});

			this.lastIndexFile = this.field.attachments.length;
		}

		// Call showInputForLinkedQuestion on OnInit to avoid infinite calls
		const tempValidate = this.field.validate?.find(
			item => item.dependsOn !== undefined
		);
		if (tempValidate && tempValidate.dependsOn) {
			this.form
				.get(tempValidate.dependsOn[0].questionName)
				?.valueChanges.subscribe(() => {
					this.showInputForLinkedQuestionValue =
						this.showInputForLinkedQuestion();
				});
		}

		// In order to nest multiple options in same field - right now this is for radio > input component
		const fields = [this.field.nameOption, this.field.name];
		// Detect Form control changes
		// Fires as soon as form control's value changes
		fields.forEach(field => {
			if (!field || this.field.type === 'file') {
				return;
			}

			this.form
				.get(field)
				?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
				.subscribe(value => {
					const validationFields = [
						'medicalDirectorApproval',
						'surgicalDirectorApproval',
						'dataCertification',
						'importCertification'
					].includes(field);

					if (
						((activeRoute['entryType'] === 'Hospital' ||
							activeRoute['entryType'] === 'Program') &&
							this.form.get(field)?.valid) ||
						validationFields
					) {
						this.store
							.saveEntryChange(
								activeRoute['hospitalId'],
								activeRoute['itemId'],
								field,
								value
							)
							.subscribe({
								error: () => {
									this.store.showErrorMessage({});
								},
							});
					}
				});
		});

		// Payors Changes
		if (activeRoute['entryType'] === 'Payor') {
			this.field.options?.forEach((option: ExtraOption) => {
				this.checkboxGroup
					.get(option.value.toString())
					?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
					.subscribe(() => {
						this.store
							.savePayorCheckboxChange(
								activeRoute['hospitalId'],
								activeRoute['year'],
								this.field.name,
								option.value.toString()
							)
							.subscribe({
								error: () => {
									this.store.showErrorMessage({});
								},
							});
					});
			});
		}

		// Team - TeamProgramCount Component
		if (
			activeRoute['entryType'] === 'Team' &&
			this.field.type === 'fields' &&
			this.field.style === 'inline'
		) {
			this.field.fields?.forEach((field: Field) => {
				this.checkboxGroup
					.get(field.name.toString())
					?.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
					.subscribe(value => {
						if (value === '' || toNumber(value) >= 0) {
							this.store
								.saveTeamProgramCountChange(
									toNumber(activeRoute['hospitalId']),
									toNumber(activeRoute['year']),
									toNumber(this.field.name),
									toNumber(field.name),
									value === '' ? null : toNumber(value)
								)
								.subscribe({
									error: () => {
										this.store.showErrorMessage({});
									},
								});
						} else {
							this.checkboxGroup.get(field.name.toString())?.setValue(null);
						}
					});
			});
		}
	}

	get checkboxGroup(): FormGroup {
		return this.form.controls[this.field.name] as FormGroup;
	}

	getDropdownControl(fieldName: string) {
		return this.form.controls[fieldName] as FormControl;
	}

	get currentFile(): ExtendedFileModel | undefined {
		if (this.uploadList.length === 0) {
			return;
		}

		return this.uploadList[this.lastIndexFile];
	}
	showInput() {
		const showIn = this.store.state.programName;
		if (isEmpty(this.field.showIn)) {
			return true;
		}
		const showInput = this.field.showIn?.includes(showIn);
		if (!showInput) {
			this.form.get(this.field.name)?.clearValidators();
		}
		return showInput;
	}

	showInputForLinkedQuestion() {
		const currentField = this.field;
		let validationItems: DependsOn[] = [];
		currentField.validate?.forEach(item => {
			if (item.dependsOn?.length) {
				validationItems = item.dependsOn;
			}
		});
		if (isEmpty(validationItems)) {
			return true;
		}
		const validValues: boolean[] = [];
		validationItems?.forEach(item => {
			const formValue = this.form.get(item.questionName);
			if (item.justFilling && formValue?.value) {
				validValues.push(true);
			} else if (formValue?.value && item.questionValues) {
				validValues.push(
					item.questionValues.some(qValue => qValue === formValue?.value)
				);
			} else {
				validValues.push(formValue?.value === item?.questionValue);
			}
		});
		const isValid = validValues.every(item => item);

		// If the input is shown, then load validator, mark as hit and revalidate.
		if (isValid) {
			this.form.get(currentField.name)?.clearValidators();
			const currentFormControl = this.form.get(currentField.name);
			const validations: ValidatorFn[] = [];

			currentField.validate?.forEach(validation => {
				if (validation.type === 'REQUIRED') {
					validations.push(
						extendedValidator({
							required: true,
							message: validation.errorMessage || 'This field is required',
						})
					);
				} else if (validation.regex) {
					validations.push(
						extendedValidator({
							pattern: validation.regex,
							message: validation.errorMessage || 'This field must be filled',
						})
					);
				}
			});
			currentFormControl?.setValidators(validations);
			currentFormControl?.updateValueAndValidity();
		} else {
			// If input is not shown, then clear validators and revalidate form control
			this.form.get(currentField.name)?.clearValidators();
			this.form.get(currentField.name)?.updateValueAndValidity();
		}

		return validValues.every(item => item);
	}

	hasDependsOn() {
		const currentField = this.field;
		let validationItems: DependsOn[] = [];
		currentField.validate?.forEach(item => {
			if (item.dependsOn?.length) {
				validationItems = item.dependsOn;
			}
		});

		if (!isEmpty(validationItems)) {
			return true;
		} else {
			return false;
		}
	}

	onFileChanged(file: File) {
		const activeRoute = this.activeRoute.snapshot.params;
		// If the survey is of the Team type, send the programID/memberId, otherwise send the surveyID
		const newFile: ExtendedFileModel =
			this.fileUploadService.constructToUploadFile(
				file,
				activeRoute['hospitalId'],
				activeRoute['entryType'] !== 'Team'
					? activeRoute['entryType']
					: 'TEAM_MEMBER_PROGRAM',
				activeRoute['entryType'] !== 'Team'
					? activeRoute['itemId']
					: this.memberId
					? this.memberId
					: this.programId,
				activeRoute['year'],
				this.field.name
			);
		this.uploadList.push(newFile);
		this.uploadFile();
	}

	uploadFile(): void {
		const request = this.constructRequestChain();
		this.executeFileUpload(request);
	}

	private constructRequestChain(): any {
		return this.fileUploadService
			.uploadFiles(this.uploadList[this.lastIndexFile])
			.pipe(
				tap(event => {
					if (event.type === HttpEventType.UploadProgress) {
						this.uploadList[this.lastIndexFile].uploadStatus.progressCount =
							Math.round(100 * (event.loaded / event.total));
					}
				}),
				catchError(error => {
					return of({ isError: true, error });
				})
			);
	}

	private executeFileUpload(request: any): void {
		request.subscribe((response: any) => {
			// TODO: Modify access to the response depending on our needs
			if (response.isError) {
				this.uploadList[this.lastIndexFile].uploadStatus.isError = true;
				this.uploadList[this.lastIndexFile].uploadStatus.errorMessage =
					response.error.statusText;
			}

			if ((response.ok || response.status === 200) && response.body) {
				this.uploadList[this.lastIndexFile].uploadStatus.isSucess = true;
				this.uploadList[this.lastIndexFile].uploadStatus.previewUrl =
					response.body.fileUrl;
				this.uploadList[this.lastIndexFile].uploadStatus.attachmentID =
					response.body.attachmentID;
				this.lastIndexFile += 1;
				this.cdr.detectChanges();
			}
		});
	}

	onRemoveFile(attachmentID: string) {
		this.fileUploadService.removeFile(attachmentID).subscribe(() => {
			const fileIndex = this.uploadList.findIndex(
				(item: ExtendedFileModel) =>
					item.uploadStatus.attachmentID === attachmentID
			);

			if (fileIndex !== -1) {
				if (this.lastIndexFile === 1) {
					this.uploadList = [];
					this.lastIndexFile -= 1;
				} else {
					this.uploadList.splice(fileIndex, 1); // Remove the item from uploadList
					this.lastIndexFile -= 1;
				}
			}
			this.cdr.detectChanges();
		});
	}
	private generateFieldName({
		name,
		nodeId,
	}: {
		name: string;
		nodeId: string;
	}) {
		return name.split(LIMITER)[0] + nodeId;
	}

	submitForm() {
		this.store.saveData();
	}

	get disabledForm() {
		return this.form.disabled;
	}

	get isRequired(): boolean {
		return !!this.field.validate?.some(item => item.type === 'REQUIRED');
	}
}
