import { Component, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CrudService } from '@services/crud-service.service';
import { SurveyStore } from 'src/app/stores/survey';
import { orderBy } from 'lodash';
import { DASHBOARD_FILTERS } from 'src/app/constants/hospital';
import { DashboardFilterType } from 'src/app/types/general';
import { HospitalType } from 'src/app/types/hospital';
import { Survey } from 'src/app/types/survey';
import { AuthService } from '@services/auth.service';
import { catchError, of, switchMap } from 'rxjs';
import { ERROR_TYPE } from '@screens/logout/logout.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	FILTER_OPTIONS = DASHBOARD_FILTERS;
	selectedFilter!: DashboardFilterType;
	hospitals: HospitalType[] = [];
	loading = false;
	hasError = false;
	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private crudService: CrudService,
		private store: SurveyStore,
		private authService: AuthService
	) {}
	ngOnInit(): void {
		this.loading = true;
		const tokenFromURL = this.activeRoute.snapshot.queryParams?.['token'] || '';
		const filterValue = this.activeRoute.snapshot.queryParams['filter'];
		const _selectedFilter =
			DASHBOARD_FILTERS.find(
				item => filterValue?.toString() === item.value.toString()
			) || DASHBOARD_FILTERS[0];
		this.selectedFilter = _selectedFilter;
		if (!tokenFromURL) {
			this.crudService.get<HospitalType[]>(`Hospitals`).subscribe({
				next: (response: HospitalType[]) => {
					this.hospitals = response;
					this.loading = false;
				},
				error: error => {
					this.loading = false;
					this.hasError = true;
					if (error.status === 401) {
						this.router.navigate(['/logout'], {
							queryParams: {
								type: ERROR_TYPE.INVALID_TOKEN,
							},
						});
					}
				},
			});
		} else {
			this.authService
				.login(tokenFromURL)
				.pipe(
					catchError(() => {
						this.router.navigate(['/logout'], {
							queryParams: {
								type: ERROR_TYPE.INVALID_TOKEN,
							},
						});
						return of([]);
					}),
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					switchMap((response: any) => {
						if (response && response.token) {
							localStorage.setItem('jwt', response.token);
							localStorage.setItem('isLogin', JSON.stringify(true));
							return this.crudService.get<HospitalType[]>(`Hospitals`);
						} else {
							this.router.navigate(['/logout'], {
								queryParams: {
									type: ERROR_TYPE.INVALID_TOKEN,
								},
							});
						}

						return of([]);
					})
				)
				.subscribe(
					(response: HospitalType[]) => {
						this.hospitals = response;
						this.loading = false;
					},
					error => {
						console.log(error);
						this.loading = false;
						this.hasError = true;
					}
				);
		}
	}

	onToggleChange(event: MatButtonToggleChange) {
		const _seletectFilter = this.FILTER_OPTIONS.find(
			item => item.value.toString() === event.value
		);
		if (_seletectFilter) {
			this.selectedFilter = _seletectFilter;
			this.updateUrl(_seletectFilter.value);
		}
	}
	filterHospitals() {
		return orderBy(this.hospitals, ['year'], ['desc']);
	}
	openSurvey(survey: Survey) {
		this.store.setSelectedSurvey(survey);
		this.router.navigate([
			'hospital',
			survey.hospitalId,
			'survey',
			survey.entryType,
			survey.entryType !== 'Payor' ? survey.id : survey.year,
			'year',
			survey.year,
		]);
	}
	private updateUrl(filter: string | number): void {
		const navigationExtras: NavigationExtras = {
			queryParams: { filter },
		};

		this.router.navigate([], navigationExtras);
	}

	trackByFn(index: number) {
		return index;
	}
}
