<div class="file-preview">
	<mat-icon
		svgIcon="file-svg"
		[class.loading]="!currentFile.uploadStatus.isSucess"></mat-icon>

	<div class="info">
		<div class="description">
			<div class="file">
				<span class="title truncate-text">{{ currentFile.file.name }}</span>
			</div>

			<span *ngIf="currentFile.file.size" class="numercial-data-small">{{
				byteConverter(currentFile.file.size)
			}}</span>
		</div>

		<app-progress
			*ngIf="showProgress && !currentFile.uploadStatus.isSucess"
			[value]="currentFile.uploadStatus.progressCount"
			color="primary" />
	</div>

	<div
		class="close"
		mwlConfirmationPopover
		popoverClass="pop-confirm"
		popoverMessage="Are you sure you want to delete this file?"
		placement="top"
		[reverseButtonOrder]="true"
		confirmText="Yes"
		cancelText="No"
		(confirm)="popConfirm()">
		<mat-icon svgIcon="close-line-svg"></mat-icon>
	</div>
</div>
