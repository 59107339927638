import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { RadioRowQuestionType } from 'src/app/types/fields/radio-button';

@Component({
	selector: 'app-radio-row-question',
	templateUrl: './radio-row-question.component.html',
	styleUrls: ['./radio-row-question.component.scss'],
})
export class RadioRowQuestionComponent {
	@Input() items: RadioRowQuestionType[] = [];
	@Output() handleChangeOption = new EventEmitter<RadioRowQuestionType>();

	changeOption(item: RadioRowQuestionType, event: MatRadioChange) {
		const updatedOptions = item.options.map(option => {
			return { ...option, checked: event.value === option.value };
		});
		this.handleChangeOption.emit({ ...item, options: updatedOptions });
	}
}
