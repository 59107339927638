/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CrudService } from './crud-service.service';
import { tokenType } from '../types/token';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private crudService: CrudService) {}

	login(token: string): Observable<tokenType> {
		return this.crudService.post('Auth/Token', {
			token,
		} as tokenType);
	}

	logout() {
		return new Promise<void>(resolve => {
			setTimeout(() => {
				localStorage.setItem('username', '');
				localStorage.setItem('isLogin', JSON.stringify(false));

				resolve();
			}, 2000);
		});
	}

	refreshToken() {
		return this.crudService.post('Auth/Refresh', {
			token: localStorage.getItem('jwt'),
		} as tokenType);
	}

	signUp(data: any) {
		return new Promise<void>(resolve => {
			setTimeout(() => {
				resolve();
			}, 2000);
		});
	}

	get isLogin(): boolean {
		return JSON.parse(localStorage.getItem('isLogin') || 'false');
	}
}
