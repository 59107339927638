<div
	class="upload"
	[ngClass]="{
		margin: fileList.length > 0 || disabled
	}">
	<h1 *ngIf="(bigLabel && label) || disabled">
		{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</h1>
	<p class="mat-body-1 body" *ngIf="(!bigLabel && label) || disabled">
		{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</p>

	<app-file
		*ngIf="currentFile"
		[showProgress]="true"
		[currentFile]="currentFile" />

	<div
		class="upload-drop"
		[class.error]="errorMessage || lastInvalids?.length"
		ngfDrop
		selectable="1"
		[(files)]="files"
		[accept]="accept"
		[(validDrag)]="validDrag"
		[class.invalid-drag]="validDrag === false"
		[class.valid-drag]="validDrag"
		[(lastInvalids)]="lastInvalids"
		*ngIf="!disabled"
		(filesChange)="onAddFile()">
		<mat-icon svgIcon="upload-cloud-svg" class="icon"></mat-icon>

		<div class="body">
			<div class="description">
				<span class="mat-body-1 title"
					>Select a file or drag and drop here</span
				>
				<span class="numercial-data info">{{ description }}</span>
			</div>

			<button mat-stroked-button color="primary" class="button">
				Select file
			</button>
		</div>
	</div>

	<span
		*ngIf="assistiveText && !disabled"
		class="error-message mat-body-2 text-hint {{ assistiveTextClass }}"
		>{{ assistiveText }}</span
	>

	<span *ngIf="localErrorMessage" class="error-message mat-body-2 text-error">{{
		localErrorMessage
	}}</span>

	<span *ngIf="lastInvalids?.length" class="error-message mat-body-2 text-error"
		>File type not supported</span
	>

	<span *ngIf="errorMessage" class="error-message mat-body-2 text-error">{{
		errorMessage
	}}</span>

	<div class="list">
		<app-file
			*ngFor="let item of fileList"
			(removeConfirm)="onRemoveEvent($event)"
			[currentFile]="item" />
	</div>
	<div *ngIf="disabled && fileList.length === 0">
		<p class="mat-body-1 body without-margin">No files uploaded</p>
	</div>
</div>
