<div *ngIf="showHeader" class="radio-inline-container header">
	<div class="space"></div>

	<div class="options">
		<mat-label *ngFor="let option of options" class="mat-body-1">{{
			option.label
		}}</mat-label>
	</div>
</div>
<div
	class="radio-inline-container"
	[formGroup]="form"
	[ngClass]="{ blue: index % 2 !== 0, gray: index % 2 === 0 }">
	<div class="container">
		<mat-label class="mat-body-1"
			>{{ label
			}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
		>
		<div class="mat-custom-input input">
			<mat-form-field
				[id]="name"
				ngDefaultControl
				style="margin-bottom: -1.25em">
				<input
					[id]="name"
					[type]="'text'"
					matInput
					[placeholder]="placeholder"
					[formControlName]="name"
					[errorStateMatcher]="errorMatcher" />
				<mat-hint>{{ assistiveText }}</mat-hint>
				<mat-error *ngIf="errorMessage !== ''">{{ errorMessage }}</mat-error>
			</mat-form-field>
		</div>
	</div>
	<mat-radio-group
		color="primary"
		class="radio-group-YN"
		[formControlName]="nameOption">
		<mat-radio-button
			*ngFor="let option of options"
			class="mat-radio-button-base"
			[value]="option.value" />
	</mat-radio-group>
</div>
