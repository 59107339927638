<div *ngIf="showHeader" class="radio-inline-container header">
	<div class="space"></div>

	<div class="options">
		<mat-label *ngFor="let option of options" class="mat-body-1">{{
			option.label
		}}</mat-label>
	</div>
</div>

<div
	class="radio-inline-container"
	[formGroup]="form"
	[ngClass]="{ blue: index % 2 !== 0, gray: index % 2 === 0 }">
	<mat-label class="mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>

	<mat-radio-group
		color="primary"
		class="radio-group-YN"
		[formControlName]="name">
		<mat-radio-button
			*ngFor="let option of options"
			class="mat-radio-button-base"
			[value]="option.value" />
	</mat-radio-group>
</div>
