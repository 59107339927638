import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
	AlerModalComponent,
	DataAlertType,
} from '@components/alerts/alert-modal/alert-modal.component';
import { SurveyStore } from 'src/app/stores/survey';
import { SURVEY_STATUS, SurveyItem } from 'src/app/types/survey';
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, AfterViewChecked {
	query = new FormControl('', Validators.required);
	isError = false;
	surveyItems$!: Observable<SurveyItem[]>;
	selectedItem$?: Observable<SurveyItem | undefined>;
	state$?: Observable<
		| {
				formTitle: string;
				hospitalName: string;
				fetching: boolean;
				hasError: boolean;
		  }
		| undefined
	>;
	formGroup$!: Observable<FormGroup>;
	status = SURVEY_STATUS;

	/**
	 * The constructor function subscribes to changes in the selectedSurveyItem state and updates the URL
	 * with the selected item's ID.
	 * @param {SurveyStore} store - The `store` parameter is of type `SurveyStore`. It is used to access
	 * the survey store and retrieve the selected survey item.
	 * @param {Router} router - The `router` parameter is an instance of the `Router` class from the
	 * Angular Router module. It is used for navigating between different routes in your application.
	 * @param {ActivatedRoute} activeRoute - The `activeRoute` parameter is an instance of the
	 * `ActivatedRoute` class from the Angular Router module. It represents the currently activated route
	 * and contains information about the route's parameters, query parameters, and other data. It is used
	 * to access and extract data from the current route.
	 */
	constructor(
		public store: SurveyStore,
		private router: Router,
		public dialog: MatDialog,
		private activeRoute: ActivatedRoute,
		private snackbar: MatSnackBar,
		private cdr: ChangeDetectorRef
	) {}
	/**
	 * The ngOnInit function initializes the component by setting the initial state of the survey items
	 * and the selected survey item, and subscribing to the surveyItems and selectedItem observables.
	 */
	async ngOnInit() {
		const activeRoute = this.activeRoute.snapshot.params;
		await this.store.onMountState({
			entryType: activeRoute['entryType'],
			surveyId: activeRoute['itemId'],
			hospitalId: activeRoute['hospitalId'],
			year: activeRoute['year'],
		});
		this.surveyItems$ = this.store.select(state => {
			return state.surveyItems;
		});
		this.selectedItem$ = this.store.select(state => {
			return state.selectedSurveyItem;
		});
		this.formGroup$ = this.store.select(state => {
			return state.parentForm;
		});
		this.state$ = this.store.select(
			({ fetching, hospitalName, formTitle, hasError }) => {
				return {
					fetching,
					hospitalName,
					formTitle,
					hasError,
				};
			}
		);
	}

	onPressItem(item: SurveyItem) {
		this.store.setState(() => ({
			selectedSurveyItem: item,
		}));

		// Automatic expansion of Team Member to Team Contact
		const activeRoute = this.activeRoute.snapshot.params;
		if (
			activeRoute['entryType'] === 'Team' &&
			item.subsectionType === 'teamMember'
		) {
			if (!isEmpty(item.subsection) && item.subsection !== undefined) {
				const teamMember = item.subsection[0];

				this.store.setState(() => ({
					selectedTeamMember: teamMember,
				}));
			}
		} else if (
			activeRoute['entryType'] === 'Team' &&
			item.subsectionType === 'teamContact'
		) {
			if (!isEmpty(item.subsection) && item.subsection !== undefined) {
				const teamContact = item.subsection[0];

				this.store.setState(() => ({
					selectedContactMember: teamContact,
				}));
			}
		}
	}

	onChangeStepState(newStatus: SURVEY_STATUS) {
		let _selectedItem: SurveyItem | undefined;
		this.selectedItem$?.subscribe(item => {
			_selectedItem = item;
		});
		if (_selectedItem) {
			_selectedItem.status = newStatus;
			this.store.replaceSurveyItem({ ..._selectedItem });
			this.onPressItem(_selectedItem);
		}
	}

	onSubmit() {
		this.store.saveData();
	}
	private navigateToDashboard() {
		this.router.navigate(['/dashboard']);
	}

	onExit() {
		/**
		 * If the form is disabled THEN the form is submitted
		 */
		if (this.store.state.parentForm.disabled) {
			this.navigateToDashboard();
			return;
		}
		const dialogRef = this.dialog.open(AlerModalComponent<ModalDataType>, {
			data: {
				title: 'Are you sure you want to exit this survey?',
				message:
					'We’ll temporarily save your work, but answers on this page will not be recorded unless you come back and submit this page.',
				buttonText: {
					done: 'Stay on survey',
					cancel: 'Save and exit',
				},
			},
		});
		dialogRef.afterClosed().subscribe((result: ModalDataType) => {
			if (result.justClose) {
				return;
			}
			if (result.submitted) {
				return;
			}
			this.store.saveDataByFormType();
			this.navigateToDashboard();
			return;
		});
	}
	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}

export type ModalDataType = DataAlertType<{ submitted: boolean }>;
