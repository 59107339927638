import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { SurveyStore } from 'src/app/stores/survey';
import {
	ErrorsType,
	SURVEY_STATUS,
	SurveyItem,
	SurveyItemId,
} from 'src/app/types/survey';

@Component({
	selector: 'app-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
	surveyItems$!: Observable<SurveyItem[]>;
	@Input() selectedSurveyItemId?: SurveyItemId;
	@Output() changeStepState = new EventEmitter<SURVEY_STATUS>();
	@Output() selectSurveyItem = new EventEmitter<SurveyItem>();
	formGroup$!: Observable<FormGroup>;
	parentFormErrors$!: Observable<ErrorsType[]>;

	constructor(private store: SurveyStore) {}

	ngOnInit(): void {
		this.formGroup$ = this.store.select(state => {
			return state.parentForm;
		});

		this.selectSurveyItem.subscribe(item => {
			this.scrollToAccordionItem(item?.title);
		});
		this.surveyItems$ = this.store.select(state => {
			return state.surveyItems;
		});
		this.parentFormErrors$ = this.store.select(state => {
			return state.parentFormErrors;
		});
		//Subscription to error state, to automatically redirect to the field with error after submit
		this.parentFormErrors$.subscribe(errors => {
			if (!isEmpty(errors)) {
				console.log('errors', errors);
				this.surveyItems$?.subscribe(state => {
					const section = state.find(item =>
						item.fields.some(field => field.name === errors[0].key)
					);

					// Set item to open accordion automatically
					this.selectSurveyItem.emit(section);
					// Scroll to field with error
					this.scrollToAccordionItem(errors[0].key, 700);
				});
			}
		});
	}

	scrollToAccordionItem(
		elementId = this.selectedSurveyItemId || '',
		duration = 500
	) {
		const mycomponent = document.getElementById(elementId.toString());

		setTimeout(() => {
			mycomponent?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'start',
			});
		}, duration);
	}
	onPressAccordion(item: SurveyItem) {
		this.selectSurveyItem.emit(item);
	}

	onSelectState(state: SURVEY_STATUS) {
		this.changeStepState.emit(state);
	}
	showSection(node: SurveyItem) {
		const showIn = this.store.state.programName;
		if (isEmpty(node.showIn)) {
			return true;
		}
		return node.showIn?.includes(showIn);
	}

	trackByFn(index: number) {
		return index;
	}
}
