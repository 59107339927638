import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() value = '';
	@Input() label = '';
	@Input() placeholder = 'Choose an Option';
	@Input() assistiveText = '';
	@Input() options: OptionType[] = [];
	@Input() control!: FormControl;
	@Input() isRequired!: boolean;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};

	get errorMessage() {
		const patternError = this.form.controls[this.name].errors;
		if (patternError) {
			return (
				patternError?.['required']?.message ||
				patternError?.['pattern']?.message ||
				''
			);
		}
		return '';
	}

	trackByFn(index: number) {
		return index;
	}
}
