<mat-drawer-container class="example-container" autosize>
	<mat-drawer #drawer class="example-sidenav" mode="side">
		<div *ngFor="let item of routes">
			<button
				*ngIf="item.type === 'link'"
				mat-raised-button
				class="nav-button"
				(click)="redirectToPage(item.url || '')">
				<mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
				{{ item.title }}
			</button>

			<mat-accordion *ngIf="item.type === 'section'">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon class="material-icons-outlined">{{
								item.icon
							}}</mat-icon>
							{{ item.title }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<button
						*ngFor="let link of item.links"
						mat-raised-button
						class="nav-button"
						(click)="redirectToPage(link.url || '')">
						{{ link.title }}
					</button>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</mat-drawer>

	<div class="sidenav-content">
		<ng-content></ng-content>
	</div>
</mat-drawer-container>
