import { SurveyEntryType, SurveyItem } from 'src/app/types/survey';
import { HOSPITAL_SCHEMA } from './hospital';
import { PAYOR_SCHEMA } from './payor';
import { PROGRAM_SCHEMA } from './program';
import { TEAM_SCHEMA } from './team';

export type FormEntryType = {
	formTitle: string;
	formDescription: string;
	sections: SurveyItem[];
};

export const formStructure: Record<SurveyEntryType, FormEntryType> = {
	Hospital: HOSPITAL_SCHEMA,
	Payor: PAYOR_SCHEMA,
	Program: PROGRAM_SCHEMA,
	Team: TEAM_SCHEMA,
};
