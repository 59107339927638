<div *ngIf="showHeaderAndFooter; else loginScreen">
	<header>
		<app-header [disableHeader]="disableHeader" />
	</header>
	<!-- Dynamic content -->
	<main layout="column">
		<router-outlet></router-outlet>
	</main>
	<app-footer />
</div>

<ng-template #loginScreen>
	<router-outlet></router-outlet>
</ng-template>
