import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-progress',
	templateUrl: './progress.component.html',
	styleUrls: ['./progress.component.scss'],
})
/**
 * ProgressComponent
 *
 * @description Component for displaying a progress-bar for indicating progress and activity.
 *
 * @Input {color} Color for the buffer bar (primary, accent and success) default primary
 * @Input {value} Numerical quantity that represents the percentage of progress, between 0-100
 *
 * @returns {ProgressComponent} Progress Component
 */
export class ProgressComponent {
	@Input() color?: string | undefined = 'primary';
	@Input() value?: number | undefined = 0;
}
