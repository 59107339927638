<mat-card class="container">
	<mat-card-content>
		<div class="header">
			<h1 class="mat-headline-5">{{ title }}</h1>
			<span class="title-data">{{ subtitle }}</span>
		</div>
		<div class="swimlane" *ngIf="localItems.length > 0">
			<app-card
				*ngFor="let item of getItemsByOpenPanel()"
				[lastUpdateDescription]="formatDateForSurvey(item)"
				[organ]="item.entryType !== 'Payor' ? item.entrySubType : undefined"
				[progress]="item.progress"
				[status]="item.status"
				[entryType]="item.entryType"
				[title]="item.generatedName"
				(handleSubmit)="onPressItem(item)"></app-card>
		</div>
		<div *ngIf="localItems.length > 4" class="container-expand-button">
			<button
				mat-button
				(click)="togglePanelOpen()"
				class="mat-body-1 full-width">
				Show {{ panelOpenState ? 'Less' : 'More' }}
				<mat-icon>{{
					panelOpenState ? 'expand_less' : 'expand_more'
				}}</mat-icon>
			</button>
		</div>

		<div *ngIf="localItems.length === 0">
			<span class="mat-headline-6 text-primary"
				>There are no surveys here yet</span
			>
		</div>
	</mat-card-content>
</mat-card>
