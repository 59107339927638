<div class="kitchen">
	<h1>Kitchen</h1>

	<br />

	<h2>FIELDS</h2>

	<br />

	<app-inline-dynamic-field />

	<br />

	<form style="padding: 0 16px" [formGroup]="myForm" (ngSubmit)="onSubmit()">
		<h3>Multi Select Check Boxes Component</h3>
		<app-checkbox
			[form]="this.myForm.controls['payors']"
			label="Select payors for delivery of RFI reports:"
			[options]="checkboxOption"
			errorMessage="Select at least one" />

		<h3>Inputs</h3>

		<app-text-input
			[form]="myForm"
			inputId="name-input"
			name="name"
			[control]="getFormControl('name')"
			[errorMatcher]="nameMatcher"
			label="Name"
			type="text"
			placeholder="Ex. John Doe">
		</app-text-input>

		<app-text-input
			[form]="myForm"
			inputId="email-input"
			name="email"
			[control]="getFormControl('email')"
			[errorMatcher]="matcher"
			label="Email"
			type="text"
			placeholder="Ex. youremail@test.com"
			assistiveText="Here you can enter your email"></app-text-input>

		<app-text-input
			[form]="myForm"
			inputId="date-input"
			name="date"
			[control]="getFormControl('date')"
			[errorMatcher]="dateMatcher"
			label="Birthday"
			type="date"
			placeholder="MM/DD/YYYY"
			assistiveText="We need your birthday"></app-text-input>
		<br />

		<h3>Dropdown</h3>
		<app-dropdown
			[form]="myForm"
			inputId="dropdown-input"
			name="role"
			[control]="getFormControl('role')"
			[errorMatcher]="roleMatcher"
			label="Role"
			placeholder="Choose an Option"
			assistiveText="Chose One"
			[options]="dropdownOptions" />
		<app-dropdown-multi-select
			[form]="myForm"
			inputId="dropdown-input"
			name="multipleRole"
			[control]="getFormControl('multipleRole')"
			[errorMatcher]="roleMatcher"
			label="Multiple Role"
			placeholder="Choose multiple options"
			assistiveText="Chose at Least One"
			[options]="dropdownOptions"
			[errorMessage]="getRoleErrorMessage()" />
	</form>

	<h3>Attachment</h3>

	<br />

	<app-upload
		[currentFile]="currentFile"
		[fileList]="uploadList"
		(fileChanged)="onFileChanged($event)"
		(removeConfirm)="onRemoveFile($event)" />

	<br />

	<h2>Radio buttons</h2>
	<!--adio Button Single Answer with labelr-->
	<h3>Radio Button Single Answer with label</h3>
	<mat-radio-group color="primary">
		<mat-radio-button class="mat-radio-button-base" value="checked" checked
			>Checked</mat-radio-button
		>
		<mat-radio-button class="mat-radio-button-base" value="Unchecked"
			>Unchecked</mat-radio-button
		>
		<mat-radio-button class="mat-radio-button-base" value="Disabled" disabled
			>Disabled</mat-radio-button
		>
	</mat-radio-group>
	<!--Radio Button Single Answer-->
	<h3>Radio Button Single Answer</h3>
	<mat-radio-group color="primary">
		<mat-radio-button
			class="mat-radio-button-base"
			value="checked-1"
			checked></mat-radio-button>
		<mat-radio-button
			class="mat-radio-button-base"
			value="Unchecked-1"></mat-radio-button>
		<mat-radio-button
			class="mat-radio-button-base"
			value="Disabled-1"
			disabled></mat-radio-button>
	</mat-radio-group>
	<!--Radio Button Single Answer with Text-->
	<h3>Radio Button Single Answer with Text</h3>
	<mat-radio-group color="primary" class="mat-radio-group-filled">
		<mat-radio-button class="mat-radio-button-filled" value="first" checked>
			First Option</mat-radio-button
		>
		<mat-radio-button class="mat-radio-button-filled" value="second"
			>Second Option</mat-radio-button
		>
		<mat-radio-button class="mat-radio-button-filled" value="third"
			>Third Option</mat-radio-button
		>
	</mat-radio-group>

	<!-- * Radio Button Answer Y/N Down the List -->
	<h3>Radio Button Answer Y/N Down the List</h3>
	<app-radio-row-question
		style="margin-bottom: 32px; padding: 16px"
		[items]="radioRowQuestions"
		(handleChangeOption)="onChangeRadioOption($event)"></app-radio-row-question>
	<!-- Single Select box -->
	<h3>Single Select box</h3>
	<mat-button-toggle-group class="mat-button-toggle-group-form">
		<mat-button-toggle checked class="mat-body-1"> Selected </mat-button-toggle>
		<mat-button-toggle class="mat-body-1"> Unselected </mat-button-toggle>
	</mat-button-toggle-group>
	<!--Multi Select Check Boxes-->
	<br />
	<h3>Multi Select Check Boxes</h3>
	<mat-checkbox
		[disableRipple]="true"
		color="primary"
		class="mat-checkbox-button-base"></mat-checkbox>
	<mat-checkbox
		[disableRipple]="true"
		color="primary"
		class="mat-checkbox-button-base"
		checked></mat-checkbox>
	<mat-checkbox
		[disableRipple]="true"
		color="primary"
		class="mat-checkbox-button-base"
		[disabled]="true"></mat-checkbox>
	<!--Multi Select with Text-->
	<h3>Multi Select with Text</h3>
	<mat-checkbox
		[disableRipple]="true"
		color="primary"
		class="mat-checkbox-button-text"
		>Check me!</mat-checkbox
	>
	<mat-checkbox
		[disableRipple]="true"
		color="primary"
		class="mat-checkbox-button-text"
		checked
		>Checked</mat-checkbox
	>
	<h2>BUTTONS</h2>
	<h3>Raised</h3>
	<div class="buttons">
		<button mat-raised-button>Basic</button>
		<button mat-raised-button color="primary">Primary</button>
		<button mat-raised-button color="accent">Accent</button>
		<button mat-raised-button color="warn">Warn</button>
		<button mat-raised-button color="success">Success</button>
		<button mat-raised-button color="kidney">kidney</button>
		<button mat-raised-button color="pancreas">pancreas</button>
		<button mat-raised-button color="liver">liver</button>
		<button mat-raised-button color="lung">lung</button>
		<button mat-raised-button color="heart">heart</button>
		<button mat-raised-button color="intestine">intestine</button>
		<button mat-raised-button disabled>Disabled</button>
		<a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
	</div>

	<br />

	<h3>Progress Component</h3>

	<br />

	<app-progress [value]="0" />
	<app-progress [value]="35" color="primary" />
	<app-progress [value]="55" color="accent" />
	<app-progress [value]="75" color="success" />

	<br />

	<h3>Stroked</h3>
	<div class="buttons">
		<button mat-stroked-button>Basic</button>
		<button mat-stroked-button color="primary">Primary</button>
		<button mat-stroked-button color="accent">Accent</button>
		<button mat-stroked-button color="success">success</button>
		<button mat-stroked-button color="kidney">kidney</button>
		<button mat-stroked-button color="pancreas">pancreas</button>
		<button mat-stroked-button color="liver">liver</button>
		<button mat-stroked-button color="lung">lung</button>
		<button mat-stroked-button color="heart">heart</button>
		<button mat-stroked-button color="intestine">intestine</button>
		<button mat-stroked-button disabled>Disabled</button>
		<a mat-stroked-button href="https://www.google.com/" target="_blank"
			>Link</a
		>
	</div>

	<br />

	<h2>DASHBOARD CARDS</h2>
	<div class="cards">
		<app-card
			subtitle="Hospital Information"
			status="New"
			lastUpdateDescription="Added 2 days ago" />

		<app-card
			subtitle="Hospital Information"
			status="In Progress"
			[progress]="30"
			lastUpdateDescription="Added 1 days ago" />

		<app-card
			subtitle="Hospital Information"
			status="Submitted"
			[progress]="100"
			lastUpdateDescription="Finished 1 day ago" />
	</div>

	<br />

	<div class="cards">
		<app-card
			title="Team"
			status="New"
			lastUpdateDescription="Added 2 days ago" />

		<app-card
			title="Team"
			status="In Progress"
			[progress]="30"
			lastUpdateDescription="Last edit 4 days ago" />

		<app-card
			title="Team"
			status="Submitted"
			[progress]="100"
			lastUpdateDescription="Finished 1 day ago" />
	</div>

	<br />

	<div class="cards">
		<app-card
			subtitle="Program Information"
			status="New"
			organ="kidney"
			lastUpdateDescription="Added 2 days ago" />

		<app-card
			subtitle="Program Information"
			status="In Progress"
			organ="pancreas"
			[progress]="30"
			lastUpdateDescription="Last edit 4 days ago" />

		<app-card
			title="Team"
			status="Submitted"
			organ="liver"
			[progress]="100"
			lastUpdateDescription="Finished 1 day ago" />
	</div>

	<br />

	<div class="cards">
		<app-card
			subtitle="Program Information"
			status="New"
			organ="lung"
			lastUpdateDescription="Added 2 days ago" />

		<app-card
			subtitle="Program Information"
			status="In Progress"
			organ="heart"
			[progress]="30"
			lastUpdateDescription="Last edit 4 days ago" />

		<app-card
			title="Team"
			status="Submitted"
			organ="intestine"
			[progress]="100"
			lastUpdateDescription="Finished 1 day ago" />
	</div>

	<br />
</div>
