import { Component, Input } from '@angular/core';
import { SURVEY_STATUS_ICONS } from 'src/app/constants/survey';
import {
	SURVEY_STATUS,
	StatusIconType,
	SurveyItem,
	SurveyItemId,
} from 'src/app/types/survey';

@Component({
	selector: 'app-survey-title',
	templateUrl: './survey-title.component.html',
	styleUrls: ['./survey-title.component.scss'],
})
export class SurveyTitleComponent {
	@Input() surveyInfo!: SurveyItem;
	@Input() startIcon?: string;
	@Input() surveyStatus: SURVEY_STATUS = SURVEY_STATUS.UNKNOWN;
	@Input() selectedSurveyItemId?: SurveyItemId;
	icon?: StatusIconType;
	status = SURVEY_STATUS;
	icons = SURVEY_STATUS_ICONS;

	/**
	 * The function returns an icon based on the status of a survey, if the status is valid.
	 * @returns the icon associated with the survey status if the status is valid and defined in the
	 * `SURVEY_STATUS_ICONS` object. If the status is not valid or not defined, it returns `undefined`.
	 */
	getIcon() {
		if (this.surveyStatus && VALID_STATUS.includes(this.surveyStatus)) {
			return SURVEY_STATUS_ICONS[this.surveyStatus];
		}
		return undefined;
	}
}

const VALID_STATUS = [SURVEY_STATUS.COMPLETE, SURVEY_STATUS.INCOMPLETE];
