<div class="mat-custom-dropdown" ngDefaultControl [formGroup]="form">
	<mat-label class="label mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>

	<mat-form-field [id]="inputId" ngDefaultControl>
		<mat-select
			hideSingleSelectionIndicator
			[(value)]="value"
			[formControlName]="name"
			[errorStateMatcher]="errorMatcher"
			[placeholder]="placeholder">
			<mat-option
				*ngFor="let item of options; trackBy: trackByFn"
				[value]="item.value"
				>{{ item.label }}</mat-option
			>
		</mat-select>
		<mat-icon class="material-icons-outlined">expand_more</mat-icon>
		<mat-hint *ngIf="!errorMessage">{{ assistiveText }}</mat-hint>
		<mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
	</mat-form-field>
</div>
