import { Component, EventEmitter, Input, Output } from '@angular/core';
import { find, isEmpty } from 'lodash';
import { StatusType, OrgansType } from 'src/app/types/general';
import { SurveyEntryType } from 'src/app/types/survey';

interface StatusModel {
	type: StatusType;
	color: string;
	text: string;
}

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
/**
 * CardComponent
 *
 * @param {string} title The title of the card. Default is 'Transplant'.
 * @param {string} subtitle The subtitle of the card. Default is an empty string.
 * @param {string|null} organ The type of organ related to the card. Can be 'kidney', 'pancreas', 'liver', 'lung', 'heart', 'intestine' or null. Default is null.
 * @param {string} status The status of the card. Can be 'new', 'in-progress' or 'submitted'. Default is 'new'.
 * @param {number} progress The progress of the task related to the card. Default is 0.
 * @param {string} lastUpdateDescription The description of the last update of the task. Default is an empty string.
 *
 * @returns CardComponent
 */
export class CardComponent {
	@Input() title?: string = 'Transplant';
	@Input() subtitle?: string = '';
	@Input() organ?: OrgansType = null;
	@Input() status?: StatusType = 'New';
	@Input() progress = 0;
	@Input() lastUpdateDescription?: string = '';
	@Input() entryType?: SurveyEntryType;
	@Output() handleSubmit = new EventEmitter();

	statusNew: StatusModel = {
		type: 'New',
		color: 'primary',
		text: 'Start survey',
	};

	statusInProgress: StatusModel = {
		type: 'In Progress',
		color: 'accent',
		text: 'Continue survey',
	};

	statusSubmitted: StatusModel = {
		type: 'Submitted',
		color: 'success',
		text: 'Open survey',
	};

	get currentStatus(): StatusModel | undefined {
		if (this.entryType !== 'Payor' && this.status === 'Submitted') {
			return {
				color: 'success',
				type: 'Submitted',
				text: 'Submitted / View only',
			};
		}
		if (this.status === 'Locked') {
			return {
				color: 'Locked',
				type: 'Locked',
				text: 'Locked / View only',
			};
		}
		if (this.status === 'New') {
			const start = this.statusNew;
			return start;
		}
		if (!isEmpty(this.progress) && this.progress < 100 && this.progress !== 0) {
			return this.statusInProgress;
		}
		if (this.status === 'Submitted') {
			const submitted = this.statusSubmitted;
			return {
				...submitted,
				text: 'Open survey',
			};
		}

		return this.statusInProgress;
	}

	onSubmit() {
		this.handleSubmit.emit();
	}
	get currentOrgan() {
		return this.organ?.toLowerCase();
	}
	get showOpenButton() {
		if (this.entryType !== 'Payor' && this.status === 'Submitted') {
			return true;
		}
		return (
			(this.entryType === 'Payor' && this.status === 'Submitted') ||
			this.status !== 'Submitted'
		);
	}
}
