import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-radio-inline',
	templateUrl: './inline.component.html',
	styleUrls: ['./inline.component.scss'],
})
export class RadioInlineComponent {
	@Input() form!: FormGroup;
	@Input() name!: string;
	@Input() label = '';
	@Input() assistiveText = '';
	@Input() options: OptionType[] | undefined = [];
	@Input() isRequired!: boolean;
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	@Input() index!: number;
	@Input() showHeader = false;
}
