/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnInit } from '@angular/core';
import { Field } from 'src/app/types/fields';
import { SurveyItem } from 'src/app/types/survey';
import { FormGroup } from '@angular/forms';
import { SubsectionType } from 'src/app/types/fields';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() item!: (SurveyItem | SubsectionType) & { subsectionType?: string };
	@Input() programId!: string | number | undefined;
	@Input() memberId!: string | number | undefined;
	constructor(private breakpointObserver: BreakpointObserver) {}
	isSmallScreen = false;
	ngOnInit(): void {
		this.breakpointObserver
			.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
			.subscribe(result => {
				this.isSmallScreen = !result.matches;
			});
	}

	/**
	 * The function returns a CSS style object with a grid-column property based on the colSpan value of a
	 * given field.
	 * @param {Field} field - The `field` parameter is an object that represents a field in a grid layout.
	 * It may have a `colSpan` property that specifies the number of columns the field should span in the
	 * grid. If the `colSpan` property is not provided, it defaults to 12 to fill all the space specially for
	 * paragraph field type.
	 * @returns An object with a single property 'grid-column' whose value is gridColumn.
	 */
	getColSpanStyle(field: Field) {
		if (this.isSmallScreen) {
			return {
				'grid-column': 'span 12 / span 12',
			};
		}
		const colSpan = field.colSpan || 12;
		const gridColumn = `span ${colSpan} / span ${colSpan}`;
		return {
			'grid-column': gridColumn,
		};
	}

	isRadioInputMix(field: Field, index: number) {
		let tempValidation = false;

		if (
			field.type === 'number' &&
			this.item.fields[index - 1].type === 'radio' &&
			this.item.fields[index - 1].style === 'inline-input'
		) {
			tempValidation = true;
		}

		return tempValidation;
	}
	get isTeamMember() {
		return this.item.subsectionType === 'teamMember';
	}
	get isTeamMedicalContact() {
		return this.item.subsectionType === 'teamContact';
	}
	get currentSubsection() {
		return this.item.subsection as SubsectionType[];
	}

	trackByFn(index: number) {
		return index;
	}
}
