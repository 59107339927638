import { Component, Input, OnInit } from '@angular/core';
import {
	FormGroup,
	FormControl,
	FormGroupDirective,
	NgForm,
	ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DependsOn, Field } from 'src/app/types/fields';
import { OptionType } from 'src/app/types/general';
import { SurveyStore } from 'src/app/stores/survey';
import { isEmpty } from 'lodash';
import { extendedValidator } from 'src/app/utils/form';
import { VALIDATIONS } from '@assets/form_schema/validations';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}

@Component({
	selector: 'app-radio-inline-input',
	templateUrl: './inline-input.component.html',
	styleUrls: ['./inline-input.component.scss'],
})
export class RadioInlineInputComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() name!: string;
	@Input() label = '';
	@Input() assistiveText = '';
	@Input() options: OptionType[] | undefined = [];
	@Input() isRequired!: boolean;
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	@Input() index!: number;
	@Input() showHeader = false;
	@Input() showTextInput = false;
	@Input() textInputField!: Field;
	matcher = new MyErrorStateMatcher();
	randomNumber!: number;
	showInput!: boolean;

	constructor(private store: SurveyStore) {}

	ngOnInit(): void {
		this.randomNumber = this.store.getNumber();
		// Call showInputForLinkedQuestion on OnInit to avoid infinite calls
		if (this.textInputField) {
			this.showInput = this.showInputForLinkedQuestion();
		}
		this.form.get(this.name)?.valueChanges.subscribe(() => {
			if (this.textInputField) {
				this.showInput = this.showInputForLinkedQuestion();
			}
		});
	}

	showInputForLinkedQuestion() {
		const currentField = this.textInputField;
		let validationItems: DependsOn[] = [];
		currentField.validate?.forEach(item => {
			if (item.dependsOn?.length) {
				validationItems = item.dependsOn;
			}
		});
		if (isEmpty(validationItems)) {
			return true;
		}
		const validValues: boolean[] = [];
		validationItems?.forEach(item => {
			const formValue = this.form.get(item.questionName);
			if (item.justFilling && formValue?.value) {
				validValues.push(true);
			} else if (formValue?.value && item.questionValues) {
				validValues.push(
					item.questionValues.some(qValue => qValue === formValue?.value)
				);
			} else {
				validValues.push(formValue?.value === item?.questionValue);
			}
		});
		const isValid = validValues.every(item => item);

		// If the input is shown, then load validator, mark as hit and revalidate.
		if (isValid) {
			const currentFormControl = this.form.get(currentField.name);
			const validations: ValidatorFn[] = [];
			validations.push(
				extendedValidator({
					required: true,
					message: VALIDATIONS.REQUIRED.errorMessage || '',
				})
			);
			currentField.validate?.forEach(validation => {
				if (validation.regex) {
					validations.push(
						extendedValidator({
							pattern: validation.regex,
							message: validation.errorMessage || 'This field must be filled',
						})
					);
				}
			});
			currentFormControl?.setValidators(validations);
			currentFormControl?.markAsTouched();
			currentFormControl?.updateValueAndValidity();
		} else {
			// If input is not shown, then clear validators and revalidate form control
			this.form.get(currentField.name)?.clearValidators();
			this.form.get(currentField.name)?.updateValueAndValidity();
		}
		return validValues.every(item => item);
	}
}
