<div class="radio-single-inline" [formGroup]="form">
	<mat-label class="mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>
	<mat-radio-group
		color="primary"
		class="mat-radio-group-filled"
		[formControlName]="name">
		<mat-radio-button
			*ngFor="let option of options"
			class="mat-radio-button-filled"
			[value]="option.value">
			{{ option.label }}</mat-radio-button
		>
	</mat-radio-group>
</div>
