<div
	*ngIf="(state$ | async)?.fetching"
	class="mat-no-scroll-container fill-screen">
	<div class="loading">
		<app-loading></app-loading>
	</div>
</div>
<div
	*ngIf="(state$ | async)?.hasError"
	class="mat-no-scroll-container fill-screen">
	<div class="loading">
		<app-error-component></app-error-component>
	</div>
</div>
<form
	[formGroup]="(formGroup$ | async)!"
	*ngIf="
		formGroup$ && !(state$ | async)?.fetching && !(state$ | async)?.hasError
	">
	<app-survey-menu
		[title]="(state$ | async)?.formTitle || ''"
		[subtitle]="(state$ | async)?.hospitalName || ''"
		(pressExit)="onExit()"
		(pressSubmit)="onSubmit()"
		(selectSurveyItem)="onPressItem($event)"
		[selectedSurveyItemId]="(selectedItem$ | async)?.title"
		[items]="(surveyItems$ | async) || []">
		<app-accordion
			[selectedSurveyItemId]="(selectedItem$ | async)?.title"
			(changeStepState)="onChangeStepState($event)"
			(selectSurveyItem)="onPressItem($event)" />
	</app-survey-menu>
</form>
