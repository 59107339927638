<section [id]="inputId" [formGroup]="form">
	<mat-checkbox
		color="primary"
		class="mat-checkbox-button-text-without-feedback"
		[disableRipple]="true"
		[formControlName]="name"
		>{{ label }}
		<span *ngIf="isRequired" class="text-error required">*</span></mat-checkbox
	>
	<mat-hint>{{ assistiveText }}</mat-hint>
	<mat-error *ngIf="errorMessage !== ''">{{ errorMessage }}</mat-error>
</section>
