/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from '@services/auth.service';
import { differenceInMilliseconds } from 'date-fns';
import { jwtType, tokenType } from '../types/token';
import { timeToRefresh } from '../constants/token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;

	constructor(
		private router: Router,
		private authService: AuthService
	) {}

	// unknow is set to cover all possible request body structures,
	// since HTTP requests can have very different bodies depending on what we are working on.
	intercept(req: HttpRequest<unknown>, next: HttpHandler) {
		// Get the auth token
		let jwt = localStorage.getItem('jwt') || undefined;

		if (jwt) {
			// Validate JWT
			const decoded: jwtType = jwtDecode(jwt);
			const currentTime = Date.now().valueOf() / 1000;
			// Since it is timestamp in unix, the result is in seconds
			const difference = differenceInMilliseconds(decoded.exp, currentTime);

			if (difference <= timeToRefresh && difference > 0 && !this.isRefreshing) {
				this.isRefreshing = true;
				this.authService.refreshToken().subscribe({
					next: (response: tokenType | unknown) => {
						if ((response as tokenType).token) {
							jwt = (response as tokenType).token;
							localStorage.setItem('jwt', jwt);
						}
						this.isRefreshing = false;
					},
					error: error => {
						console.error('Error refreshing token: ', error);
						this.isRefreshing = false;
					},
				});
			} else if (decoded && decoded.exp && decoded.exp < currentTime) {
				localStorage.setItem('isLogin', JSON.stringify(false));
				localStorage.removeItem('jwt');
				return next.handle(req);
			}
		}
		// Clone the request and replace the original headers with
		// cloned headers, updated with the authorization.
		const authReq = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${jwt}`),
		});

		// send cloned request with header to the next handler.
		return next.handle(authReq);
	}
}
