import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Field } from 'src/app/types/fields';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-inline-dynamic-field',
	templateUrl: './inline-dynamic-field.component.html',
	styleUrls: ['./inline-dynamic-field.component.scss'],
})
export class InlineDynamicComponent {
	@Input() form!: FormGroup;
	@Input() title!: string;
	@Input() name!: string;
	@Input() label = '';
	@Input() assistiveText = '';
	@Input() options: OptionType[] | undefined = [];
	@Input() fields: Field[] | undefined = [];
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	@Input() index!: number;
	@Input() showHeader = false;

	showInitials(label: string) {
		if (window.innerWidth < 910) {
			return this.getShortName(label);
		}

		return label;
	}

	getShortName(name: string) {
		return name
			.split('/')
			.map(n => n[0])
			.join('');
	}

	trackByFn(index: number) {
		return index;
	}
}
