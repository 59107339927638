<form [formGroup]="(formGroup$ | async)!">
	<mat-accordion multi>
		<ng-container formArrayName="sections">
			<ng-container
				*ngFor="
					let item of (surveyItems$ | async) || [];
					let index = index;
					trackBy: trackByFn
				">
				<app-accordion-item
					*ngIf="showSection(item)"
					[id]="item.title"
					[item]="item"
					[index]="index"
					[selectedId]="selectedSurveyItemId"
					(changeStepState)="onSelectState($event)"
					(selectSurveyItem)="onPressAccordion($event)" /> </ng-container
		></ng-container>
	</mat-accordion>
</form>
