import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

// Services
import { AuthInterceptor } from './interceptors/auth-interceptor';

// Routes
import { AppRoutingModule } from './app-routing.module';
import { ReleasesRoutingModule } from '@screens/releases/releases.routing';

// Screens
import { AppComponent } from './app.component';
import { DashboardComponent } from '@screens/dashboard/dashboard.component';
import { ReleasesComponent } from '@screens/releases/releases.component';
import { OverviewComponent } from '@screens/releases/overview/overview.component';
import { ForecastsComponent } from '@screens/releases/forecasts/forecasts.component';
import { OutlookComponent } from '@screens/releases/outlook/outlook.component';
import { RealtimeComponent } from '@screens/releases/realtime/realtime.component';
import { KitchenComponent } from '@screens/kitchen/kitchen.component';
import { LogoutComponent } from '@screens/logout/logout.component';

// Components
import { MaterialModule } from '@modules/material.module';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { NavComponent } from '@components/nav/nav.component';
import { CardComponent } from '@components/card/card.component';
import { SurveyMenuComponent } from '@components/survey/survey-menu/survey-menu.component';
import { SurveyTitleComponent } from '@components/survey/survey-title/survey-title.component';
import { AlerModalComponent } from '@components/alerts/alert-modal/alert-modal.component';
import { SurveyComponent } from '@screens/survey/survey.component';
import { UploadComponent } from '@components/drag-and-drop/upload/upload.component';
import { FileComponent } from '@components/drag-and-drop/file/file.component';
import { AccordionComponent } from '@components/survey/accordion/wrapper/accordion.component';
import { ItemComponent } from '@components/survey/accordion/item/item.component';
import { CardSwimlaneComponent } from '@components/card-swimlane/card-swimlane.component';
import { RadioRowQuestionComponent } from '@components/radio-button/radio-row-question/radio-row-question.component';
import { DropdownComponent } from '@components/dropdown/dropdown.component';
import { TextInputComponent } from '@components/inputs/text-input/text-input.component';
import { FormComponent } from '@components/form/form.component';
import { DynamicFieldComponentComponent } from '@components/form/dynamic-field-component/dynamic-field-component.component';
import { CheckboxComponent } from '@components/checkbox/checkbox.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { RadioBoxComponent } from '@components/radios/box/box.component';
import { RadioInlineComponent } from '@components/radios/inline/inline.component';
import { RadioSingleComponent } from '@components/radios/single/single.component';
import { RadioSingleInlineComponent } from '@components/radios/single-inline/single-inline.component';
import { ProgressComponent } from '@components/progress/progress.component';
import { TextAreaComponent } from '@components/inputs/text-area/text-area.component';
import { DropdownMultiSelectComponent } from '@components/dropdown-multi-select/dropdown-multi-select.component';
import { ErrorComponentComponent } from '@components/error-component/error-component.component';
import { RadioInlineInputComponent } from '@components/radios/inline-input/inline-input.component';
import { InlineDynamicComponent } from './components/inline-dynamic-field/inline-dynamic-field.component';
import { SingleCheckboxComponent } from './components/checkbox/single-checkbox/single-checkbox.component';
import { RadioWithInputComponent } from './components/radios/radio-with-input/radio-with-input.component';
import { TeamMemberFormComponent } from './components/form/team-member-form/team-member-form.component';
import { TeamContactFormComponent } from './components/form/team-contact-form/team-contact-form.component';
import { TeamMemberNamePipe } from './team-member-name.pipe';

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		ReleasesComponent,
		OverviewComponent,
		ForecastsComponent,
		OutlookComponent,
		RealtimeComponent,
		KitchenComponent,
		LogoutComponent,
		HeaderComponent,
		FooterComponent,
		NavComponent,
		CardComponent,
		SurveyMenuComponent,
		SurveyTitleComponent,
		AlerModalComponent,
		SurveyComponent,
		AccordionComponent,
		UploadComponent,
		CardSwimlaneComponent,
		FileComponent,
		ItemComponent,
		CardSwimlaneComponent,
		RadioRowQuestionComponent,
		DropdownComponent,
		TextInputComponent,
		FormComponent,
		DynamicFieldComponentComponent,
		CheckboxComponent,
		LoadingComponent,
		RadioBoxComponent,
		RadioInlineComponent,
		RadioSingleComponent,
		ProgressComponent,
		TextAreaComponent,
		DropdownMultiSelectComponent,
		RadioSingleInlineComponent,
		ErrorComponentComponent,
		RadioInlineInputComponent,
		InlineDynamicComponent,
		SingleCheckboxComponent,
		RadioWithInputComponent,
		TeamMemberFormComponent,
		TeamContactFormComponent,
    TeamMemberNamePipe,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReleasesRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		HttpClientModule,
		ReactiveFormsModule,
		ngfModule,
		ConfirmationPopoverModule.forRoot({}),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
