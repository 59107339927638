<div
	class="mat-custom-dropdown"
	[ngClass]="{ 'mat-multi-select-filled': getValue() }"
	ngDefaultControl
	[formGroup]="form">
	<mat-label class="label mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>

	<mat-form-field [id]="inputId" ngDefaultControl>
		<mat-select
			multiple
			hideSingleSelectionIndicator
			[(value)]="value"
			[formControlName]="name"
			[errorStateMatcher]="errorMatcher"
			[placeholder]="placeholder">
			<input [matChipInputFor]="chipGrid" />
			<mat-select-trigger>
				<mat-chip-grid #chipGrid>
					<mat-chip-row
						color="primary"
						class="chip"
						*ngFor="let chip of renderChips()"
						(removed)="removeChip(chip)">
						<span class="white-color mat-body-1">
							{{ chip.label }}
						</span>
						<button matChipRemove class="white-color">
							<mat-icon>clear</mat-icon>
						</button>
					</mat-chip-row>
				</mat-chip-grid>
			</mat-select-trigger>
			<mat-option *ngFor="let item of options" [value]="item.value">{{
				item.label
			}}</mat-option>
		</mat-select>
		<mat-icon class="material-icons-outlined">expand_more</mat-icon>
		<mat-hint *ngIf="!errorMessage">{{ assistiveText }}</mat-hint>
		<mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
	</mat-form-field>
</div>
