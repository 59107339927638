import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FIELD_TYPES } from 'src/app/types/fields';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextInputComponent),
			multi: true,
		},
	],
})
export class TextInputComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() value = '';
	@Input() label = '';
	@Input() bigLabel = false;
	@Input() placeholder = '';
	@Input() assistiveText = '';
	@Input() type: FIELD_TYPES = 'text';
	@Input() control!: FormControl;
	@Input() isRequired!: boolean;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	@Input() hideHint = false;
	@Input() defaultValue: undefined | string | number = '';
	prevType: FIELD_TYPES = this.type;
	ngOnInit(): void {
		if (this.type === 'date') {
			if (!this.defaultValue) {
				this.prevType = this.type;
				this.type = 'text';
			}
		}
	}
	focusInput() {
		if (this.prevType === 'date' && !this.defaultValue) {
			this.type = 'date';
		}
	}
	get errorMessage() {
		const patternError = this.form.controls[this.name].errors;
		if (patternError) {
			return (
				patternError?.['required']?.message ||
				patternError?.['pattern']?.message ||
				''
			);
		}
		return '';
	}
	onKeyDown(event: KeyboardEvent) {
		if (this.type === 'number') {
			const inputElement = event.target as HTMLInputElement;
			const inputValue = inputElement.value;
			const isNumericKey = /^\d$/.test(event.key);
			if (!isNumericKey) {
				event.preventDefault();
				return;
			}
			this.form.get(this.name)?.setValue(inputValue);
		}
	}
}
