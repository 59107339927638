import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export const ERROR_TYPE = {
	GENERAL: 'GENERAL',
	INVALID_TOKEN: 'INVALID_TOKEN',
};
@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
	type = ERROR_TYPE.GENERAL;

	constructor(private activeRoute: ActivatedRoute) {
		this.activeRoute.queryParams.subscribe(params => {
			const urlType = params?.['type'] || '';
			if (urlType in ERROR_TYPE) {
				this.type = urlType;
			}
		});
	}
	ngOnInit(): void {
		localStorage.clear();
	}

	messages = {
		[ERROR_TYPE.GENERAL]: {
			title: 'YOUR SESSION HAS ENDED',
			message:
				'We’re sorry, the page you requested could not be found. Please return to UNet to refresh your session.',
		},
		[ERROR_TYPE.INVALID_TOKEN]: {
			title: 'YOUR TOKEN IS INVALID',
			message: 'Please return to UNet to refresh your session.',
		},
	};

	get error() {
		return this.messages[this.type];
	}
}
