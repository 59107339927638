import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '@guards/auth.guard';

// Routes
import { ReleasesRoutingModule } from '@screens/releases/releases.routing';

// Components
import { DashboardComponent } from '@screens/dashboard/dashboard.component';
import { SurveyComponent } from '@screens/survey/survey.component';
import { KitchenComponent } from '@screens/kitchen/kitchen.component';
import { LogoutComponent } from '@screens/logout/logout.component';

const routes: Routes = [
	{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'hospital/:hospitalId/survey/:entryType/:itemId/year/:year',
		component: SurveyComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'kitchen',
		component: KitchenComponent,
		canActivate: [AuthGuard],
	},
	{ path: 'logout', component: LogoutComponent },
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
		ReleasesRoutingModule,
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
