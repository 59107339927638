import { RadioRowQuestionType } from '../types/fields/radio-button';
import { OptionType } from '../types/general';

export const radioRowQuestionsFake: RadioRowQuestionType[] = [
	{
		id: 'question_1',
		title:
			'Blood and Bone Marrow: Autologous and/or Allogenic Tandem or Sequential',
		options: [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no', checked: true },
		],
	},
	{
		id: 'question_2',
		title: 'Blood and Bone Marrow: Peripheral Blood Stem Cell',
		options: [
			{ label: 'Yes', value: 'yes', checked: true },
			{ label: 'No', value: 'no' },
		],
	},
	{
		id: 'question_1',
		title: 'Islet Cell: Living Donor',
		options: [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		],
	},
];

export const dropdownOptionsFake: OptionType[] = [
	{ label: 'Primary Physican', value: 'primary-physican' },
	{ label: 'Assistant Physician', value: 'assistant-physician' },
	{ label: 'Nurse', value: 'nurse' },
];

export const checkboxOptionsFake: OptionType[] = [
	{ label: 'Item 1 - Default', value: 'item-1' },
	{ label: 'Item 2 - Default', value: 'item-2', checked: true },
	{ label: 'Item 3 - Default', value: 'item-3' },
];
