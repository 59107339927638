import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class SnackBarService {
	constructor(private snackbar: MatSnackBar) {}
	showErrorMessage({
		title = 'A network error has occurred, please try again',
	}: {
		title?: string;
		duration?: number;
	}) {
		return this.snackbar.open(title, 'Ok', {
			duration: 5000,
			panelClass: ['mat-snackbar-error'],
		});
	}
	showSucess({
		title = 'Success',
		duration = 3000,
	}: {
		title?: string;
		duration?: number;
	}) {
		return this.snackbar.open(title, undefined, {
			duration: duration,
			panelClass: ['mat-snackbar-info'],
		});
	}

	showInfo({
		title = 'Saving data',
		duration = 3000,
	}: {
		title?: string;
		duration?: number;
	}) {
		return this.snackbar.open(title, undefined, {
			duration,
			panelClass: ['mat-snackbar-info'],
		});
	}
}
