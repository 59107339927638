<h1
	*ngIf="
		field.type === 'paragraph' && showInput() && showInputForLinkedQuestionValue
	"
	class="fade-in-out paragraph {{ field.style || '' }}">
	{{ field.label }}
</h1>

<div
	*ngIf="
		field.type !== 'paragraph' && showInput() && showInputForLinkedQuestionValue
	"
	[id]="field.name"
	class="fade-in-out"
	[formGroup]="form"
	class="full-width fade-in-out">
	<ng-container [ngSwitch]="field.type">
		<!-- Text Input -->
		<app-text-input
			*ngSwitchCase="'text'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />
		<app-text-input
			*ngSwitchCase="'tel'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />
		<app-text-input
			*ngSwitchCase="'number'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />
		<app-text-input
			*ngSwitchCase="'date'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[defaultValue]="field.value"
			[placeholder]="field.placeholder || 'MM/DD/YYYY'"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />

		<app-text-area
			*ngSwitchCase="'textarea'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />

		<!-- Dropdown -->
		<app-dropdown
			*ngSwitchCase="'select'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[label]="field.label"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher"
			[options]="field.options || []"
			[control]="getDropdownControl(field.name)" />

		<!-- Dropdown -->
		<app-dropdown-multi-select
			*ngSwitchCase="'multiselect'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[label]="field.label"
			[placeholder]="field.placeholder || ''"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher"
			[options]="field.options || []"
			[control]="getDropdownControl(field.name)" />

		<!-- Checkboxes -->
		<app-single-checkbox
			*ngSwitchCase="'single-checkbox'"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[label]="field.label"
			[assistiveText]="field.assistiveText || ''"
			[isRequired]="field.showRequired || isRequired"
			[errorMatcher]="matcher" />
		<ng-container *ngSwitchCase="'checkbox'">
			<app-checkbox
				*ngIf="!field.style"
				[form]="checkboxGroup"
				[inputId]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="checkboxMatcher" />

			<!-- Exclusive Component for Payor Survey -->
			<app-inline-dynamic-field
				*ngIf="field.style === 'inline'"
				[index]="index"
				[form]="checkboxGroup"
				title="Payor"
				[name]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[fields]="undefined"
				[showHeader]="isFirstDynamicFieldInline"
				[errorMatcher]="checkboxMatcher" />
		</ng-container>

		<!-- Fields Inline Component -->
		<!-- Exclusive Component for Team Role Count - Team Survey -->
		<app-inline-dynamic-field
			*ngSwitchCase="'fields'"
			[index]="index"
			[form]="checkboxGroup"
			[name]="field.name"
			[label]="field.label"
			[assistiveText]="field.assistiveText || ''"
			[options]="undefined"
			[fields]="field.fields"
			[showHeader]="isFirstTeamRoleCountInline"
			[errorMatcher]="checkboxMatcher" />

		<!-- Radios -->
		<ng-container *ngSwitchCase="'radio'">
			<!-- Single Select Box -->
			<app-radio-box
				*ngIf="!field.style"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[smallLabel]="hasDependsOn() || field.smallLabel || false"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher" />

			<!-- Radio Button Answer Y/N Down the List -->
			<app-radio-inline
				*ngIf="field.style === 'inline'"
				[index]="index"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[showHeader]="isFirstRadioInline"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher" />

			<app-radio-with-input
				*ngIf="field.style === 'input'"
				[index]="index"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[nameOption]="field.nameOption || ''"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[placeholder]="field.placeholder || ''"
				[showHeader]="isFirstRadioInline"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher" />

			<!-- Radio Button Answer Y/N Down the List with Text Input -->
			<app-radio-inline-input
				*ngIf="field.style === 'inline-input'"
				[index]="index"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[showHeader]="isFirstRadioInline"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher"
				[textInputField]="nextRadioInputMix" />

			<!-- Radio Button Single Answer -->
			<app-radio-single
				*ngIf="field.style === 'single'"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher" />

			<!-- Radio Button Single Answer with Text -->
			<app-radio-single-inline
				*ngIf="field.style === 'single-inline'"
				[form]="form"
				[name]="field.name"
				[label]="field.label"
				[assistiveText]="field.assistiveText || ''"
				[options]="field.options"
				[isRequired]="field.showRequired || isRequired"
				[errorMatcher]="matcher" />
		</ng-container>

		<!-- File Upload -->
		<app-upload
			*ngSwitchCase="'file'"
			[form]="form"
			[name]="field.name"
			[label]="field.label"
			[bigLabel]="field.bigLabel || false"
			[assistiveText]="field.assistiveText || ''"
			[currentFile]="currentFile"
			[fileList]="uploadList"
			[validate]="field.validate || []"
			[isRequired]="field.showRequired || isRequired"
			[assistiveTextClass]="field.assistiveTextClass"
			(fileChanged)="onFileChanged($event)"
			(removeConfirm)="onRemoveFile($event)" />
		<button
			*ngSwitchCase="'submit-button'"
			color="primary"
			type="button"
			class="submit-button"
			(click)="submitForm()"
			[disabled]="disabledForm"
			mat-raised-button>
			{{ field.label }}
		</button>
		<!-- Divider -->
		<div *ngSwitchCase="'divider'" class="divider"></div>
		<p
			*ngIf="
				field.type === 'paragraph-body' &&
				showInput() &&
				showInputForLinkedQuestionValue
			"
			class="fade-in-out mat-body-1 body">
			{{ field.label }}
		</p>
	</ng-container>
</div>
