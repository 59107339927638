/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlerModalComponent } from '@components/alerts/alert-modal/alert-modal.component';
import { ModalDataType } from '@screens/survey/survey.component';
import { CrudService } from '@services/crud-service.service';
import { cloneDeep } from 'lodash';
import { Observable, debounceTime } from 'rxjs';
import { DEBOUNCE_TIME } from 'src/app/constants/commons';
import { SurveyStore } from 'src/app/stores/survey';
import { SubsectionType } from 'src/app/types/fields';

@Component({
	selector: 'app-team-member-form',
	templateUrl: './team-member-form.component.html',
	styleUrls: ['./team-member-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMemberFormComponent implements OnInit, OnDestroy {
	@Input() form!: FormGroup;
	@Input() subsections!: SubsectionType[];
	formChanged = false;
	loadingTeamMember = false;
	loadingProgram: Record<string, boolean> = {};
	loadingRemoveTeamMember: Record<string, boolean> = {};
	loadingRemoveProgramsMember: Record<string, boolean> = {};
	selectedTeamMember$?: Observable<SubsectionType | undefined>;
	selectedTeamMemberProgam$?: Observable<SubsectionType | undefined>;
	teamMemberCount = 0;

	constructor(
		private store: SurveyStore,
		private crudService: CrudService,
		public dialog: MatDialog
	) {}

	private sub: any;

	private toogleLoadingTeamMember() {
		this.loadingTeamMember = !this.loadingTeamMember;
	}
	setLoadingRemoveTeamMember(id: string) {
		this.loadingRemoveTeamMember[id] = true;
	}
	removeLoadingRemoveTeamMember(id: string) {
		delete this.loadingRemoveTeamMember[id];
	}
	getIsDeletingTeamMemberLoading(member: SubsectionType) {
		return Boolean(this.loadingRemoveTeamMember?.[member.id]);
	}
	// Add program loadings
	setLoadingProgramToTeamMember(id: string) {
		this.loadingProgram[id] = true;
	}
	removeLoadingProgramToTeamMember(id: string) {
		delete this.loadingProgram[id];
	}
	getIsAddingProgramToTeamMemberLoading(member: SubsectionType) {
		return Boolean(this.loadingProgram?.[member.id]);
	}
	// Delete program Loadings
	setDeleteLoadingProgramToTeamMember(id: string) {
		this.loadingRemoveProgramsMember[id] = true;
	}
	removeDeleteLoadingProgramToTeamMember(id: string) {
		delete this.loadingRemoveProgramsMember[id];
	}
	getIsDeletingProgramToTeamMemberLoading(member: SubsectionType) {
		return Boolean(this.loadingRemoveProgramsMember?.[member.id]);
	}
	resetFormChanged() {
		this.formChanged = false;
	}
	setFormChanged() {
		this.formChanged = true;
	}
	ngOnInit(): void {
		// SelectedTeamMember and selectedTeamMemberProgam observables to monitor which Accordion
		// should remain open during renders when adding or removing members or programs.
		this.selectedTeamMember$ = this.store.select(state => {
			return state.selectedTeamMember;
		});
		this.selectedTeamMemberProgam$ = this.store.select(state => {
			return state.selectedTeamMemberProgam;
		});
		this.subsections.forEach((member, memberIndex) => {
			this.teamMemberCount++;
			member.fields.forEach(field => {
				this.sub = this.form
					?.get(memberIndex?.toString())
					?.get(field.name)
					?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
					.subscribe(value => {
						this.formChanged &&
							value?.toString() !== field.value?.toString() &&
							this.store.saveTeamEntryChange({
								field,
								value,
								teamMemberId: member.id,
							});
					});
			});
			member.subsection?.forEach((program, programIndex) => {
				program.fields.forEach(programField => {
					this.sub = this.form
						?.get(memberIndex.toString())
						?.get('programs')
						?.get(programIndex.toString())
						?.get(programField.name)
						?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
						.subscribe(value => {
							this.formChanged &&
								value?.toString() !== programField.value?.toString() &&
								this.store.saveProgramEntryInTeamMemberChange({
									field: programField,
									programId: program.id,
									teamMemberId: program.parentId || '',
									value,
								});
						});
				});
			});
		});
		this.sub = this.form.valueChanges.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(() => {
			this.formChanged = true;
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	getCurrentSection(index: number) {
		return this.form.get(index.toString()) as FormGroup;
	}
	getCurrentProgramSection(index: number, programIndex: number) {
		return this.form
			.get(index.toString())
			?.get('programs')
			?.get(programIndex.toString()) as FormGroup;
	}
	addProgramToTeamMember({
		member,
		memberIndex,
	}: {
		member: SubsectionType;
		memberIndex: number;
		}) {
		this.resetFormChanged();
		this.setLoadingProgramToTeamMember(member.id);
		this.crudService
			.post(
				`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamMember/${member.id}/TeamMemberProgram`
			)
			.subscribe({
				next: (response: any) => {
					const programIdFromApi = response.teamMemberProgramId;
					const newParentForm = cloneDeep(this.store.state.parentForm);
					const currentMemberSelection = newParentForm
						?.get('sections')
						?.get('0')
						?.get(memberIndex.toString())
						?.get('programs') as unknown as FormArray;
					const newTeamMemberProgram: SubsectionType = {
						fields: this.store.state.programSchema,
						id: programIdFromApi,
						parentId: member.id,
						type: 'teamProgramComponent',
					};
					const myArrayData = this.store.buildTeamFormArray({
						subsection: [newTeamMemberProgram],
						title: '',
						fields: [],
					});
					myArrayData.forEach(group => {
						currentMemberSelection.push(group);
					});
					const newSurveyItems = cloneDeep(this.store.state.surveyItems);
					newSurveyItems[0].subsection?.[memberIndex].subsection?.push(
						newTeamMemberProgram
					);

					// determine new programIndex
					let programIndex = 0;
					member.subsection?.forEach(program => programIndex++);

					// add subscriptions to team member program fields
					newTeamMemberProgram.fields.forEach(programField => {
						currentMemberSelection
							?.get(programIndex.toString())
							?.get(programField.name)
							?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
							.subscribe(value => {
								value?.toString() !== programField.value?.toString() &&
									this.store.saveProgramEntryInTeamMemberChange({
										field: programField,
										programId: programIdFromApi,
										teamMemberId: member.id || '',
										value,
									});
							});
					});

					this.store.setState(() => ({
						surveyItems: newSurveyItems,
						parentForm: newParentForm,
					}));
					setTimeout(() => {
						this.openTeamMember(member);
					}, 300);
					setTimeout(() => {
						this.openTeamMemberProgam(newTeamMemberProgram);
					}, 300);
				},
				error: () => {
					this.store.showErrorMessage({
						title: 'Error adding a new program, try again later.',
					});
					this.removeLoadingProgramToTeamMember(member.id);
				},
				complete: () => {
					this.removeLoadingProgramToTeamMember(member.id);
				},
			});
	}
	removeProgramFromTeamMember({
		member,
		memberIndex,
		program,
		programIndex,
	}: {
		member: SubsectionType;
		memberIndex: number;
		programIndex: number;
		program: SubsectionType;
	}) {
		const dialogRef = this.dialog.open(AlerModalComponent<ModalDataType>, {
			data: {
				title: 'Are you sure you want to delete?',
				buttonText: {
					done: 'Yes, delete',
					cancel: 'No, do not delete',
				},
			},
		});
		dialogRef.afterClosed().subscribe((result: ModalDataType) => {
			if (result.justClose) {
				return;
			}
			if (result.submitted) {
				this.resetFormChanged();
				this.setDeleteLoadingProgramToTeamMember(program.id);
				this.crudService
					.delete(
						`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamMember/${program.parentId}/TeamMemberProgram/${program.id}`
					)
					.subscribe({
						next: () => {
							const newParentForm = cloneDeep(this.store.state.parentForm);
							const newSurveyItems = cloneDeep(this.store.state.surveyItems);
							const currentMemberSelection = newParentForm
								?.get('sections')
								?.get('0')
								?.get(memberIndex.toString())
								?.get('programs') as unknown as FormArray;
							const currentMember = newSurveyItems[0].subsection![memberIndex];
							currentMember.subsection?.splice(programIndex, 1);
							currentMemberSelection.removeAt(programIndex);
							this.store.setState(() => ({
								surveyItems: newSurveyItems,
								parentForm: newParentForm,
							}));
							setTimeout(() => {
								this.openTeamMember(member);
							}, 300);
						},
						error: () => {
							this.store.showErrorMessage({
								title: 'Error deleting this program, try again later.',
							});
							this.removeDeleteLoadingProgramToTeamMember(program.id);
						},
						complete: () => {
							this.removeDeleteLoadingProgramToTeamMember(program.id);
						},
					});
				return;
			}
			return;
		});
	}
	addTeamMember() {
		this.resetFormChanged();
		this.toogleLoadingTeamMember();
		this.crudService
			.post(
				`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamMember`
			)
			.subscribe({
				next: (response: any) => {
					const memberIdFromApi = response.id;
					const newParentForm = cloneDeep(this.store.state.parentForm);
					const currentSelection = newParentForm
						?.get('sections')
						?.get('0') as FormGroup;
					const newTeamMember: SubsectionType = {
						fields: this.store.state.teamMemberSchema,
						id: memberIdFromApi,
						type: 'teamMemberComponent',
						subsection: [],
					};
					const myArrayData = this.store.buildTeamFormArray({
						subsection: [newTeamMember],
						title: '',
						fields: [],
					});
					let controlLength = (
						currentSelection.controls as unknown as FormGroup[]
					).length;
					myArrayData.forEach(group => {
						currentSelection.addControl(controlLength.toString(), group);
						controlLength++;
					});

					// add subscriptions to new team member fields
					newTeamMember.fields.forEach(field => {
						currentSelection
							.get(this.teamMemberCount.toString())
							?.get(field.name)
							?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
							.subscribe(value => {
								this.formChanged &&
									value?.toString() !== field.value?.toString() &&
									this.store.saveTeamEntryChange({
										field,
										value,
										teamMemberId: memberIdFromApi,
									});
							});
					});

					this.teamMemberCount++;

					const newSurveyItems = cloneDeep(this.store.state.surveyItems);
					newSurveyItems[0].subsection?.push(newTeamMember);
					this.store.setState(() => ({
						surveyItems: newSurveyItems,
						parentForm: newParentForm,
					}));

					this.openTeamMember(newTeamMember);
				},
				error: () => {
					this.store.showErrorMessage({
						title: 'Error adding a new user, try again later. ',
					});
					this.toogleLoadingTeamMember();
				},
				complete: () => {
					this.toogleLoadingTeamMember();
				},
			});
	}
	removeTeamMember({
		member,
		memberIndex,
	}: {
		member: SubsectionType;
		memberIndex: number;
	}) {
		const dialogRef = this.dialog.open(AlerModalComponent<ModalDataType>, {
			data: {
				title: 'Are you sure you want to delete?',
				buttonText: {
					done: 'Yes, delete',
					cancel: 'No, do not delete',
				},
			},
		});
		dialogRef.afterClosed().subscribe((result: ModalDataType) => {
			if (result.justClose) {
				return;
			}
			if (result.submitted) {
				this.resetFormChanged();
				this.setLoadingRemoveTeamMember(member.id);
				this.crudService
					.delete(
						`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamMember/${member.id}`
					)
					.subscribe({
						next: () => {
							const newParentForm = cloneDeep(this.store.state.parentForm);
							const newSurveyItems = cloneDeep(this.store.state.surveyItems);
							const currentSelection = newParentForm
								?.get('sections')
								?.get('0') as FormGroup;
							const currentSection = newSurveyItems[0].subsection;
							currentSection?.splice(memberIndex, 1);
							currentSelection.removeControl(memberIndex.toString());
							(currentSelection.controls as unknown as unknown[]).splice(
								memberIndex,
								1
							);
							this.store.setState(() => ({
								surveyItems: newSurveyItems,
								parentForm: newParentForm,
							}));
						},
						error: () => {
							this.store.showErrorMessage({
								title: 'Error deleting this user, try again later.',
							});
							this.removeLoadingRemoveTeamMember(member.id);
						},
						complete: () => {
							this.removeLoadingRemoveTeamMember(member.id);
						},
					});
			}
			return;
		});
	}

	openTeamMember(item: SubsectionType) {
		this.store.setState(() => ({
			selectedTeamMember: item,
		}));
	}

	openTeamMemberProgam(item: SubsectionType) {
		this.store.setState(() => ({
			selectedTeamMemberProgam: item,
		}));
	}

	getProgramName(item: SubsectionType, programIndex: number) {
		const index = this.form.value[0].programs?.[programIndex].programId - 1;
		const name = item.fields?.[0].options?.[index]?.['label'];
		if (name) {
			return name;
		}
		return `Program #${programIndex + 1}`;
	}
	get isDisabledForm() {
		return this.form.disabled;
	}

	// return by item.id in case the user deletes a Team Member from the middle of the list, so that all subsequent members don't have to be re-rendered
	trackByFn(index: number, item: any) {
		return item.id;
	}
}
