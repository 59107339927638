/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtendedFileModel } from '@models/file.model';
import { CrudService } from './crud-service.service';
import { SurveyEntryType } from '../types/survey';

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(private crudService: CrudService) {}

	constructToUploadFile(
		file: File,
		hospitalId: string,
		entryType: SurveyEntryType,
		surveyId: string,
		year: string,
		entityField: string
	): ExtendedFileModel {
		const newFile: ExtendedFileModel = {
			file: file,
			uploadUrl: `Attachment?hospitalId=${hospitalId}&entityId=${surveyId}&entityType=${entryType.toUpperCase()}&year=${year}&entityField=${entityField}`,
			uploadStatus: {
				isSucess: false,
				isError: false,
				errorMessage: '',
				progressCount: 0,
				previewUrl: '',
				attachmentID: '',
			},
		};

		return newFile;
	}

	uploadFiles(toUploadFile: ExtendedFileModel): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', toUploadFile.file, toUploadFile.file.name);

		return this.crudService.post(toUploadFile.uploadUrl, formData as any, true);
	}

	removeFile(attachmentID: string): Observable<void> {
		return this.crudService.delete(`Attachment?id=${attachmentID}`);
	}
}
