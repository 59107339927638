import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SURVEY_STATUS, SurveyItem, SurveyItemId } from 'src/app/types/survey';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SurveyStore } from 'src/app/stores/survey';
import { isEmpty, isNumber } from 'lodash';

@Component({
	selector: 'app-survey-menu',
	templateUrl: './survey-menu.component.html',
	styleUrls: ['./survey-menu.component.scss'],
})
export class SurveyMenuComponent implements OnInit, AfterViewChecked {
	@Input() items!: SurveyItem[];
	@Input() title!: string;
	@Input() subtitle!: string;
	@Input() selectedSurveyItemId?: SurveyItemId;
	@Input() disabledSubmit = false;
	@Output() selectSurveyItem = new EventEmitter<SurveyItem>();
	@Output() pressSubmit = new EventEmitter<void>();
	@Output() pressExit = new EventEmitter<void>();
	open = true;

	treeControl = new NestedTreeControl<SurveyItem>(node => node.items);
	dataSource = new MatTreeNestedDataSource<SurveyItem>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		public store: SurveyStore,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.dataSource.data = this.items.map((item, index) => ({
			...item,
			index,
		}));
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
			.subscribe(() => {
				this.open = true;
			});
	}

	hasChild = (_: number, node: SurveyItem) => {
		return !!node.items && node.items.length > 0;
	};

	onSelect(item: SurveyItem) {
		this.selectSurveyItem.emit(item);
	}
	onSubmit() {
		this.pressSubmit.emit();
	}
	onExit() {
		this.pressExit.emit();
	}
	toggleOpenMenu() {
		this.open = !this.open;
	}
	getSurveyStatus(node: SurveyItem) {
		node.showIn;
		if (!isNumber(node.index)) {
			return SURVEY_STATUS.UNKNOWN;
		}
		const currentFormGroup = this.store.state.parentForm
			.get('sections')
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			?.get(node.index!.toString());
		const formValues = Object.values(currentFormGroup?.value);
		const totalFilledFields = formValues.filter(
			value => value !== null && value !== undefined
		).length;

		if (totalFilledFields === 0) {
			return SURVEY_STATUS.UNKNOWN;
		}
		if (
			currentFormGroup?.valid ||
			currentFormGroup?.status === 'VALID' ||
			currentFormGroup?.status === 'DISABLED'
		) {
			// IF it is a Team Survey, validate that there is the mandatory number of Team Members Roles and Team Contact Types
			if (this.store.entryType === 'Team') {
				if (node.title === 'Primary Roles') {
					const isTeamMemberFormValid = this.store.hasCorrectMembers();
					return isTeamMemberFormValid
						? SURVEY_STATUS.COMPLETE
						: SURVEY_STATUS.INCOMPLETE;
				} else if (node.title === 'Team Contacts') {
					const isTeamContactFormValid = this.store.hasCorrectContacts();
					return isTeamContactFormValid
						? SURVEY_STATUS.COMPLETE
						: SURVEY_STATUS.INCOMPLETE;
				} else {
					return SURVEY_STATUS.COMPLETE;
				}
			} else {
				return SURVEY_STATUS.COMPLETE;
			}
		}

		return SURVEY_STATUS.INCOMPLETE;
	}
	showSection(node: SurveyItem) {
		const showIn = this.store.state.programName;
		if (isEmpty(node.showIn)) {
			return true;
		}
		return node.showIn?.includes(showIn);
	}
	get currentOrgan() {
		return this.store.state.programName;
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
