import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
	routes = [
		{
			title: 'Dashboard',
			url: '/',
			type: 'link',
			icon: 'space_dashboard',
		},
		{
			title: 'Releases',
			type: 'section',
			icon: 'event_available',
			links: [
				{
					title: 'Overview',
					url: '/releases',
				},
				{
					title: 'Forecasts',
					url: '/releases/forecasts',
				},
				{
					title: 'Outlook',
					url: '/releases/outlook',
				},
				{
					title: 'Real Time',
					url: '/releases/realtime',
				},
			],
		},
	];
	isOpen = false;

	constructor(private router: Router) {}

	redirectToPage(url: string) {
		this.router.navigate([url]);
	}

	handleNav(): void {
		this.isOpen = !this.isOpen;
	}
}
