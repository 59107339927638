import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() label = '';
	@Input() assistiveText = '';
	@Input() options: OptionType[] | undefined = [];
	@Input() isRequired!: boolean;
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
}
