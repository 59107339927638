import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	showHeaderAndFooter = true;
	disableHeader = false;

	constructor(
		public authService: AuthService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private router: Router
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (event.urlAfterRedirects.includes('survey')) {
					this.disableHeader = true;
				}
				if (event.url.includes('logout')) {
					this.disableHeader = true;
					this.showHeaderAndFooter = true;
				}
				if (event.url.includes('dashboard')) {
					this.disableHeader = false;
					this.showHeaderAndFooter = true;
				}
			}
		});
		/**
		 * Register the custom svgs in Material UI Lib
		 */
		this.matIconRegistry
			.addSvgIcon('complete-svg', this.setPath('../assets/icons/complete.svg'))
			.addSvgIcon(
				'incomplete-svg',
				this.setPath('../assets/icons/incomplete.svg')
			)
			.addSvgIcon('exit-svg', this.setPath('../assets/icons/exit.svg'))
			.addSvgIcon(
				'upload-cloud-svg',
				this.setPath('../assets/icons/upload-cloud.svg')
			)
			.addSvgIcon(
				'close-line-svg',
				this.setPath('../assets/icons/close-line.svg')
			)
			.addSvgIcon('file-svg', this.setPath('../assets/icons/file.svg'))
			.addSvgIcon('logout', this.setPath('assets/icons/logout.svg'))
			.addSvgIcon('more-svg', this.setPath('../assets/icons/more.svg'))
			.addSvgIcon(
				'add-group-svg',
				this.setPath('../assets/icons/add-group.svg')
			)
			.addSvgIcon('person', this.setPath('../assets/icons/person.svg'));
	}
	private setPath(url: string): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
