<section [id]="inputId" [formGroup]="form">
	<h1 *ngIf="label">
		{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</h1>
	<p *ngFor="let item of options">
		<mat-checkbox
			[disableRipple]="true"
			[formControlName]="item.value"
			color="primary"
			class="mat-checkbox-button-text"
			>{{ item.label }}</mat-checkbox
		>
	</p>
	<mat-hint>{{ assistiveText }}</mat-hint>
	<mat-error *ngIf="form?.errors?.['required']"
		>This field is required</mat-error
	>
</section>
