/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { PatchObjectType } from '../types/general';

@Injectable({
	providedIn: 'root',
})
export class CrudService {
	private apiUrl = environment.baseUrl;

	constructor(private http: HttpClient) {}

	get<T>(endpoint: string) {
		const url = `${this.apiUrl}/${endpoint}`;
		const headers = this.getRequestOptions();
		return this.http.get<T>(url, headers);
	}

	post<T>(
		endpoint: string,
		data: Record<string, object | string | FormData> = {},
		enableReportProgress = false
	) {
		const url = `${this.apiUrl}/${endpoint}`;
		let extraParams = {};

		if (enableReportProgress) {
			extraParams = {
				// observe and reportProgress options are required to capture the file upload progress
				observe: 'events',
				reportProgress: true,
			};
		}

		return this.http.post<T>(url, data, {
			...this.getRequestOptions(),
			...extraParams,
		});
	}

	put<T>(endpoint: string, data: Record<string, object | string>) {
		const url = `${this.apiUrl}/${endpoint}`;
		return this.http.put<T>(url, data, this.getRequestOptions());
	}
	patch<T>(
		endpoint: string,
		data?: Record<string, object | string> | PatchObjectType[]
	) {
		const url = `${this.apiUrl}/${endpoint}`;
		return this.http.patch<T>(url, data, this.getRequestOptions());
	}

	delete<T>(endpoint: string) {
		const url = `${this.apiUrl}/${endpoint}`;
		return this.http.delete<T>(url, this.getRequestOptions());
	}

	private getRequestOptions() {
		const headers = new HttpHeaders();

		return { headers };
	}
}
