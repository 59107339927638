import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SURVEY_FILTER_TYPES, Survey } from 'src/app/types/survey';
import { DASHBOARD_FILTERS } from 'src/app/constants/hospital';
import { getDateForSurvey } from 'src/app/utils/date';

@Component({
	selector: 'app-card-swimlane',
	templateUrl: './card-swimlane.component.html',
	styleUrls: ['./card-swimlane.component.scss'],
})
export class CardSwimlaneComponent implements OnInit {
	@Input() title = 'Title';
	@Input() subtitle = '2023';
	@Input() items: Survey[] = [];
	@Output() handleItemClick = new EventEmitter<Survey>();
	panelOpenState = false;
	FILTER_OPTIONS = DASHBOARD_FILTERS;
	localItems: Survey[] = [];

	constructor(private activeRoute: ActivatedRoute) {
		// Filter Functionality
		this.activeRoute.queryParams.subscribe(params => {
			const filterValue = params['filter'];

			if (filterValue !== 'all') {
				const _selectedFilter =
					DASHBOARD_FILTERS.find(
						item => filterValue?.toString() === item.value.toString()
					) || DASHBOARD_FILTERS[0];
				const tempItems = this.items.filter(
					item => item.status === _selectedFilter.label
				);
				this.localItems = tempItems;
			} else {
				this.localItems = this.items;
			}
		});
	}

	ngOnInit(): void {
		this.localItems = this.items;
	}

	getItemsByOpenPanel() {
		return this.localItems
			.sort((a, b) => {
				if (
					a.status?.toString() === SURVEY_FILTER_TYPES.IN_PROGRESS &&
					b.status?.toString() !== SURVEY_FILTER_TYPES.IN_PROGRESS
				) {
					return -1;
				}
				if (
					b.status?.toString() === SURVEY_FILTER_TYPES.IN_PROGRESS &&
					a.status?.toString() !== SURVEY_FILTER_TYPES.IN_PROGRESS
				) {
					return 1;
				}
				return a.status?.localeCompare(b.status?.toString() || '') || 1;
			})
			.slice(0, this.panelOpenState ? this.localItems.length : 4);
	}

	togglePanelOpen() {
		this.panelOpenState = !this.panelOpenState;
	}
	onPressItem(survey: Survey) {
		this.handleItemClick.emit(survey);
	}
	formatDateForSurvey(survey: Survey) {
		return getDateForSurvey(survey);
	}
}
