import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-dropdown-multi-select',
	templateUrl: './dropdown-multi-select.component.html',
	styleUrls: ['./dropdown-multi-select.component.scss'],
})
export class DropdownMultiSelectComponent {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() value = '';
	@Input() label = '';
	@Input() placeholder = 'Choose an Option';
	@Input() assistiveText = '';
	@Input() options: OptionType[] = [];
	@Input() control!: FormControl;
	@Input() isRequired!: boolean;
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	getValue() {
		return String(this.form.get(this.name)?.value || '');
	}
	renderChips() {
		const stringValues = String(this.form.get(this.name)?.value || '');
		const values = stringValues.split(',');
		return this.options.filter(option =>
			values.find(values => values === option.value)
		);
	}

	removeChip(chip: OptionType) {
		const stringValues = String(this.form.get(this.name)?.value || '');
		const values = stringValues.split(',');
		const index = values.indexOf(chip.value.toString());
		if (index !== -1) {
			values.splice(index, 1);
		}

		this.form.get(this.name)?.setValue(values);
	}
}
