import { SURVEY_STATUS, StatusIconType } from '../types/survey';

export const SURVEY_STATUS_ICONS: Record<SURVEY_STATUS, StatusIconType> = {
	COMPLETE: {
		isSvg: true,
		name: 'complete-svg',
	},
	INCOMPLETE: {
		isSvg: true,
		name: 'incomplete-svg',
	},
	UNKNOWN: {
		isSvg: false,
		name: '',
	},
};
