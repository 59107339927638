import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
	selector: 'app-single-checkbox',
	templateUrl: './single-checkbox.component.html',
	styleUrls: ['./single-checkbox.component.scss'],
})
export class SingleCheckboxComponent {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() label = '';
	@Input() assistiveText = '';
	@Input() isRequired!: boolean;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	get errorMessage() {
		const patternError = this.form.controls[this.name].errors;
		if (patternError) {
			return (
				patternError?.['required']?.message ||
				patternError?.['pattern']?.message ||
				''
			);
		}
		return '';
	}
}
