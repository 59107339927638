<div class="radio-box" [formGroup]="form">
	<h1 *ngIf="!smallLabel">
		{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</h1>
	<mat-label *ngIf="smallLabel" class="mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>

	<mat-button-toggle-group
		[formControlName]="name"
		class="mat-button-toggle-group-form">
		<mat-button-toggle
			*ngFor="let item of options"
			class="mat-body-1"
			[value]="item.value">
			{{ item.label }}
		</mat-button-toggle>
	</mat-button-toggle-group>
	<mat-error *ngIf="form.controls?.[name]?.errors?.['required']"
		>This field is required</mat-error
	>
	<mat-hint>{{ assistiveText }}</mat-hint>
</div>
