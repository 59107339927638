import { DashboardFilterType } from '../types/general';
import { SURVEY_FILTER_TYPES } from '../types/survey';

export const DASHBOARD_FILTERS: DashboardFilterType[] = [
	{
		value: SURVEY_FILTER_TYPES.ALL,
		label: 'All',
		class: 'mat-black',
	},
	{
		value: SURVEY_FILTER_TYPES.NEW,
		label: 'New',
		class: 'mat-outline-primary',
	},
	{
		value: SURVEY_FILTER_TYPES.IN_PROGRESS,
		label: 'In Progress',
		class: 'mat-outline-orange',
	},
	{
		value: SURVEY_FILTER_TYPES.SUBMITTED,
		label: 'Submitted',
		class: 'mat-outline-green',
	},
];

export function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
