import { Component, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { ERROR_TYPE } from '@screens/logout/logout.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	@Input() disableHeader = false;

	constructor(
		private router: Router,
		private authService: AuthService
	) { }

	openQuestions(): void {
		const pdfUrl = '../../../assets/files/UNOS_RFI_2024_Form_Questions.pdf';

		// Open the PDF file in a new tab
		window.open(pdfUrl, '_blank');
	}

	openGuide(): void {
		const pdfUrl =  '../../../assets/files/UNOS_RFI_User_Guide.pdf';

		// Open the PDF file in a new tab
		window.open(pdfUrl, '_blank');
	}

	logout(): void {
		this.router.navigate(['/logout'], {
			queryParams: {
				type: ERROR_TYPE.GENERAL,
			},
		});
	}
	get dashboardRoute() {
		return '/dashboard';
	}
}
