/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlerModalComponent } from '@components/alerts/alert-modal/alert-modal.component';
import { ModalDataType } from '@screens/survey/survey.component';
import { CrudService } from '@services/crud-service.service';
import { cloneDeep } from 'lodash';
import { Observable, debounceTime } from 'rxjs';
import { DEBOUNCE_TIME } from 'src/app/constants/commons';
import { SurveyStore } from 'src/app/stores/survey';
import { SubsectionType } from 'src/app/types/fields';

@Component({
	selector: 'app-team-contact-form',
	templateUrl: './team-contact-form.component.html',
	styleUrls: ['./team-contact-form.component.scss'],
})
export class TeamContactFormComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() subsections!: SubsectionType[];
	loadingTeamContact = false;
	loadingRemoveContactTeam: Record<string, boolean> = {};
	formChanged = false;
	selectedContactMember$?: Observable<SubsectionType | undefined>;

	resetFormChanged() {
		this.formChanged = false;
	}
	setFormChanged() {
		this.formChanged = true;
	}
	private toogleLoadingTeamContact() {
		this.loadingTeamContact = !this.loadingTeamContact;
	}
	setLoadingRemoveContactTeam(id: string) {
		this.loadingRemoveContactTeam[id] = true;
	}
	removeLoadingRemoveContactTeam(id: string) {
		delete this.loadingRemoveContactTeam[id];
	}
	getIsDeletingContactTeamLoading(contact: SubsectionType) {
		return Boolean(this.loadingRemoveContactTeam?.[contact.id]);
	}
	constructor(
		private store: SurveyStore,
		private crudService: CrudService,
		public dialog: MatDialog
	) {}
	findDifferentItem(arrayValues: string[]) {
		const count = new Map<string, number>();

		arrayValues.forEach(item => {
			count.set(item, (count.get(item) || 0) + 1);
		});

		const diffValues = arrayValues.filter(item => count.get(item) === 1);

		return diffValues;
	}

	ngOnInit(): void {
		this.selectedContactMember$ = this.store.select(state => {
			return state.selectedContactMember;
		});
		this.subsections.forEach((contact, memberIndex) => {
			contact.fields.forEach(field => {
				this.form
					?.get(memberIndex.toString())
					?.get(field.name)
					?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
					.subscribe(value => {
						if (field.name === 'teamContactPrograms') {
							const prevValue = cloneDeep(field.value || []) as any;
							field.value = value;
							const programValues = this.findDifferentItem([
								...prevValue,
								...value,
							]);
							if (!programValues.length) {
								return;
							}
							programValues.forEach(programValue => {
								this.store.saveProgramToTeamContactEntryChange({
									programId: programValue,
									teamContactId: contact.id,
									onError: () => {
										field.value = prevValue;
									},
								});
							});

							return;
						}

						value.toString() !== field.value?.toString() &&
							this.store.saveTeamContactEntryChange({
								field,
								value,
								teamContactId: contact.id,
							});
					});
			});
		});
	}

	getCurrentSection(index: number) {
		return this.form.get(index.toString()) as FormGroup;
	}
	addContact() {
		this.resetFormChanged();
		this.toogleLoadingTeamContact();
		this.crudService
			.post(
				`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamContact`
			)
			.subscribe({
				next: (response: any) => {
					const contactIdFromApi = response.id;
					const newParentForm = cloneDeep(this.store.state.parentForm);
					const currentSelection = newParentForm
						?.get('sections')
						?.get('2') as FormGroup;
					const newContactMember: SubsectionType = {
						fields: this.store.state.contactSchema,
						id: contactIdFromApi,
						type: 'teamContactComponent',
						subsection: [],
					};
					const myArrayData = this.store.buildTeamFormArray({
						subsection: [newContactMember],
						title: '',
						fields: [],
					});
					let controlLength = (
						currentSelection.controls as unknown as FormGroup[]
					).length;
					myArrayData.forEach(group => {
						currentSelection.addControl(controlLength.toString(), group);
						controlLength++;
					});

					const newSurveyItems = cloneDeep(this.store.state.surveyItems);
					newSurveyItems[2].subsection?.push(newContactMember);

					this.store.setState(() => ({
						surveyItems: newSurveyItems,
						parentForm: newParentForm,
					}));
					setTimeout(() => {
						this.openContactMember(newContactMember);
					}, 300);
				},
				error: () => {
					this.store.showErrorMessage({
						title: 'Error adding a new user, try again later. ',
					});
					this.toogleLoadingTeamContact();
				},
				complete: () => {
					this.toogleLoadingTeamContact();
				},
			});
	}
	removecontact({
		contact,
		contactIndex,
	}: {
		contact: SubsectionType;
		contactIndex: number;
	}) {
		const dialogRef = this.dialog.open(AlerModalComponent<ModalDataType>, {
			data: {
				title: 'Are you sure you want to delete?',
				buttonText: {
					done: 'Yes, delete',
					cancel: 'No, do not delete',
				},
			},
		});
		dialogRef.afterClosed().subscribe((result: ModalDataType) => {
			if (result.justClose) {
				return;
			}
			if (result.submitted) {
				this.resetFormChanged();
				this.setLoadingRemoveContactTeam(contact.id);
				this.crudService
					.delete(
						`Hospitals/${this.store.hospitalId}/TeamEntries/${this.store.surveyId}/TeamContact/${contact.id}`
					)
					.subscribe({
						next: () => {
							const newParentForm = cloneDeep(this.store.state.parentForm);
							const newSurveyItems = cloneDeep(this.store.state.surveyItems);
							const currentSelection = newParentForm
								?.get('sections')
								?.get('2') as FormGroup;
							const currentSection = newSurveyItems[2].subsection;
							currentSection?.splice(contactIndex, 1);
							currentSelection.removeControl(contactIndex.toString());
							(currentSelection.controls as unknown as unknown[]).splice(
								contactIndex,
								1
							);
							this.store.setState(() => ({
								surveyItems: newSurveyItems,
								parentForm: newParentForm,
							}));
						},
						error: () => {
							this.store.showErrorMessage({
								title: 'Error deleting this user, try again later.',
							});
							this.removeLoadingRemoveContactTeam(contact.id);
						},
						complete: () => {
							this.removeLoadingRemoveContactTeam(contact.id);
						},
					});
			}
		});
	}
	openContactMember(item: SubsectionType) {
		this.store.setState(() => ({
			selectedContactMember: item,
		}));
	}
	getContactName(index: number) {
		const { name } = this.form.value?.[index] || {};
		if (name) {
			return `${name}`.trim();
		}
		return `Team contact #${index + 1}`;
	}
	get isDisabledForm() {
		return this.form.disabled;
	}

	trackByFn(index: number) {
		return index;
	}
}
