<div class="filter-container" *ngIf="!loading && !hasError">
	<mat-button-toggle-group
		[value]="selectedFilter.value"
		(change)="onToggleChange($event)">
		<mat-button-toggle
			*ngFor="let filter of FILTER_OPTIONS; trackBy: trackByFn"
			[value]="filter.value"
			[ngClass]="filter.class"
			class="button mat-body-1">
			{{ filter.label }}
		</mat-button-toggle>
	</mat-button-toggle-group>

	<!-- TODO: Uncomment in the next sprint after the demo -->
	<!-- <mat-button-toggle class="more-button mat-body-1 button mat-outline-gray">
		<mat-icon svgIcon="more-svg"></mat-icon>More Filter</mat-button-toggle
	> -->
</div>

<div class="dashboard mat-no-scroll-container">
	<div *ngIf="loading && !hasError" class="loading">
		<app-loading></app-loading>
	</div>
	<div *ngIf="!loading && hasError" class="loading">
		<app-error-component></app-error-component>
	</div>
	<div *ngIf="!loading && !hasError">
		<app-card-swimlane
			*ngFor="let hospital of filterHospitals(); trackBy: trackByFn"
			[title]="hospital.hospitalName"
			[subtitle]="hospital.year.toString()"
			[items]="hospital.entries || []"
			(handleItemClick)="openSurvey($event)"></app-card-swimlane>
	</div>
</div>
