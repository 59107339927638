/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnChanges,
} from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { ExtendedFileModel } from '@models/file.model';
import { ErrorStateMatcher } from '@angular/material/core';
import { Validate } from 'src/app/types/fields';
import { extendedValidator } from 'src/app/utils/form';
import { isEmpty } from 'lodash';

@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss'],
})
/**
 * UploadComponent
 *
 * @description Component for file upload
 *
 * @Input {string} description Help information for accepted file types
 *
 * @Input {string} accept Allowed MIME Types
 *
 * @Input {number} maxSize Maximum size allowed in Bytes (bytes . 1024 = 1k . 1048576 = 1mb)
 *
 * @Input {ExtendedFileModel | undefined} currentFile The current file
 *
 * @Input {ExtendedFileModel[]} fileList List of files
 *
 * @Output {string} fileChanged Emits the file Changed
 *
 * @Output {EventEmitter<string>} removeConfirm Emits the confirmation to remove a file
 *
 * @returns UploadComponent
 */
export class UploadComponent implements OnChanges {
	// Component Params
	@Input() description =
		'PDF, Word, Excel, Powerpoint, ZIP, PNG and JPEG file size no more than 50MB';
	@Input() accept =
		'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/jpg,image/jpeg';
	@Input() maxSize = 52428800; // Default 50 MB
	// @Input() maxSize = 1000000; // Default 50 MB
	@Input() currentFile: ExtendedFileModel | undefined;
	@Input() fileList: ExtendedFileModel[] = [];
	@Input() assistiveTextClass?: string = '';
	@Output() fileChanged = new EventEmitter<File>();
	@Output() removeConfirm = new EventEmitter();
	files: File[] = [];
	localErrorMessage = '';
	// Fomr Params
	@Input() form!: FormGroup;
	@Input() name!: string;
	@Input() label = '';
	@Input() bigLabel = false;
	@Input() assistiveText = '';
	@Input() isRequired!: boolean;
	@Input() validate: Validate[] = [];
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	validDrag!: any;
	lastInvalids!: any;

	ngOnChanges(): void {
		// If there is a change in the current element, change the class
		this.checkUploadForm();
	}

	onAddFile() {
		if (this.files[0].size <= this.maxSize) {
			this.fileChanged.emit(this.files[0]);
			this.localErrorMessage = '';
		} else {
			this.localErrorMessage = 'File size exceeds allowable size';
		}

		this.files = [];
	}

	onRemoveEvent(attachmentID: string) {
		this.removeConfirm.emit(attachmentID);
		this.checkUploadForm();
	}

	get errorMessage() {
		const patternError = this.form.controls[this.name].errors;
		if (patternError) {
			return (
				patternError?.['required']?.message ||
				patternError?.['pattern']?.message ||
				''
			);
		}
		return '';
	}

	checkUploadForm() {
		if (!isEmpty(this.fileList)) {
			this.form.get(this.name)?.clearValidators();
			this.form.get(this.name)?.updateValueAndValidity();
		} else {
			const validations: ValidatorFn[] = [];
			this.validate?.forEach(validation => {
				if (validation.type === 'REQUIRED') {
					validations.push(
						extendedValidator({
							required: true,
							message: validation.errorMessage || 'This field is required',
						})
					);
				}
				if (validation.regex) {
					validations.push(
						extendedValidator({
							pattern: validation.regex,
							message: validation.errorMessage || 'This field must be filled',
						})
					);
				}
			});
			this.form.get(this.name)?.setValidators(validations);
			this.form.get(this.name)?.updateValueAndValidity();
			this.form.get(this.name)?.markAsTouched;
		}
	}
	get disabled() {
		return this.form.disabled;
	}
}
