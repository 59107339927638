<ul class="root">
	<li class="container" *ngFor="let item of items">
		<p class="mat-body-1">
			{{ item.title }}
		</p>
		<mat-radio-group
			color="primary"
			class="radio-group-YN"
			(change)="changeOption(item, $event)">
			<mat-radio-button
				*ngFor="let option of item.options"
				class="mat-radio-button-base"
				[checked]="option.checked"
				[value]="option.value"></mat-radio-button>
		</mat-radio-group>
	</li>
</ul>
