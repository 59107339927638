<form *ngIf="item.fields.length > 0" [formGroup]="form" class="form-container">
	<ng-container
		*ngFor="let field of item.fields; let index = index; trackBy: trackByFn">
		<div
			*ngIf="!isRadioInputMix(field, index)"
			[ngStyle]="getColSpanStyle(field)">
			<app-dynamic-field-component
				[index]="index"
				[field]="field"
				[fields]="item.fields"
				[form]="form"
				[programId]="programId"
				[memberId]="memberId" />
		</div>
	</ng-container>
</form>

<form *ngIf="isTeamMember" [formGroup]="form">
	<app-team-member-form [form]="form" [subsections]="currentSubsection" />
</form>

<form *ngIf="isTeamMedicalContact" [formGroup]="form">
	<app-team-contact-form [form]="form" [subsections]="currentSubsection" />
</form>
