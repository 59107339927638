<div
	class="survey-title"
	[class.unselected]="selectedSurveyItemId !== surveyInfo.title"
	[class.completed]="surveyStatus === status.COMPLETE"
	[class.incompleted]="surveyStatus === status.INCOMPLETE"
	[class.selected]="selectedSurveyItemId === surveyInfo.title">
	<mat-icon *ngIf="startIcon" class="mat-icon-rtl-mirror">{{
		startIcon
	}}</mat-icon>
	<div class="label">
		<span mat-button class="mat-body-1 step-name">{{ surveyInfo.title }} </span>
	</div>
	<mat-icon
		class="right-icon"
		*ngIf="!!getIcon()"
		[svgIcon]="getIcon()?.name!"></mat-icon>
	<mat-icon class="right-icon" *ngIf="!!getIcon() && !getIcon()?.isSvg">{{
		getIcon()?.name!
	}}</mat-icon>
</div>
