<mat-expansion-panel
	hideToggle
	[expanded]="item.title === selectedId"
	(opened)="openPanel()"
	(closed)="closePanel()">
	<mat-expansion-panel-header>
		<mat-panel-title>
			<h1>{{ item.title }}</h1>
			<img
				*ngIf="!isOpen"
				src="assets/icons/Dropddown_Open.png"
				alt="Expand"
				class="expand-icon" />
			<img
				*ngIf="isOpen"
				src="assets/icons/Dropddown_Close.png"
				alt="Collapse"
				class="collapse-icon" />
		</mat-panel-title>
	</mat-expansion-panel-header>
	<app-form [form]="currentSection" [item]="item"></app-form>
</mat-expansion-panel>
