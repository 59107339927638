import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
	selector: 'app-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.scss'],
})
export class AlerModalComponent<T> {
	title = '';
	message = '';
	cancelButtonText = 'Cancel';
	doneButtonText = 'Done';
	customData: DataAlertType<T> = {
		submitted: false,
		justClose: false,
	} as unknown as DataAlertType<T>;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			message: string;
			buttonText: { cancel?: string; done?: string };
			data: T;
		},
		private dialogRef: MatDialogRef<AlerModalComponent<T>>
	) {
		if (data) {
			this.title = data.title || this.title;
			this.message = data.message || this.message;
			if (data.buttonText) {
				this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
				this.doneButtonText = data.buttonText?.done || this.doneButtonText;
			}
			let initialState = { submitted: false };
			if (data.data) {
				initialState = { ...initialState, ...data.data };
			}
			this.customData = initialState as DataAlertType<T>;
		}
		this.dialogRef.updateSize('auto', 'auto');
	}

	onConfirmClick(): void {
		this.customData.submitted = !this.customData?.submitted;
		this.dialogRef.close(this.customData);
	}
	onCloseForm(): void {
		this.customData.justClose = true;
		this.dialogRef.close(this.customData);
	}
}

export type DataAlertType<T> = {
	submitted: boolean;
	justClose: boolean;
} & T;
