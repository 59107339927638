import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '@guards/auth.guard';

// Components
import { ReleasesComponent } from './releases.component';
import { OverviewComponent } from './overview/overview.component';
import { ForecastsComponent } from './forecasts/forecasts.component';
import { OutlookComponent } from './outlook/outlook.component';
import { RealtimeComponent } from './realtime/realtime.component';

// Routes
const routes: Routes = [
	{
		path: 'releases',
		component: ReleasesComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				component: OverviewComponent,
			},
			{
				path: 'forecasts',
				component: ForecastsComponent,
			},
			{
				path: 'outlook',
				component: OutlookComponent,
			},
			{
				path: 'realtime',
				component: RealtimeComponent,
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ReleasesRoutingModule {}
