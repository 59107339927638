import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FIELD_TYPES } from 'src/app/types/fields';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
	selector: 'app-text-area',
	templateUrl: './text-area.component.html',
	styleUrls: ['./text-area.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextInputComponent),
			multi: true,
		},
	],
})
export class TextAreaComponent {
	@Input() form!: FormGroup;
	@Input() inputId!: string;
	@Input() name!: string;
	@Input() value = '';
	@Input() label = '';
	@Input() bigLabel = false;
	@Input() placeholder = '';
	@Input() assistiveText = '';
	@Input() type: FIELD_TYPES = 'textarea';
	@Input() control!: FormControl;
	@Input() isTextArea = false;
	@Input() isRequired!: boolean;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
	prevType: FIELD_TYPES = this.type;
}
