import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ExtendedFileModel } from '@models/file.model';

@Component({
	selector: 'app-file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.scss'],
})
/**
 * FileComponent
 *
 * @description Component for displaying file information and handling file operations
 *
 * @Input {ExtendedFileModel} currentFile The file model to be displayed
 * @Input {boolean} showProgress Flag to show progress
 * @Output {EventEmitter} removeConfirm Emits the file ID when the remove action is confirmed
 *
 * @returns {FileComponent} Instance of the file component
 */
export class FileComponent {
	@Input() currentFile!: ExtendedFileModel;
	@Input() showProgress = false;
	@Output() removeConfirm = new EventEmitter();

	byteConverter(bytes: number) {
		const K_UNIT = 1024;
		const SIZES = ['Bytes', 'KB', 'MB'];

		if (bytes == 0) return '0 Byte';

		const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
		const resp =
			parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(2)) + ' ' + SIZES[i];

		return resp;
	}

	popConfirm() {
		this.removeConfirm.emit(this.currentFile.uploadStatus.attachmentID);
	}
}
