import { NgModule } from '@angular/core';

// Components
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
@NgModule({
	imports: [
		MatSlideToggleModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		FormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatCardModule,
		MatSidenavModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		MatProgressBarModule,
		MatChipsModule,
		MatTreeModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatRadioModule,
		MatCheckboxModule,
		MatSelectModule,
		MatSnackBarModule,
		MatDividerModule,
		MatAutocompleteModule,
	],
	exports: [
		MatSlideToggleModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		FormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatCardModule,
		MatSidenavModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		MatProgressBarModule,
		MatChipsModule,
		MatTreeModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatRadioModule,
		MatCheckboxModule,
		MatSelectModule,
		MatDividerModule,
		MatAutocompleteModule,
	],
})
export class MaterialModule {}
