/* eslint-disable prettier/prettier */
import { Validate } from 'src/app/types/fields';

export type ValidationTypes =
	| 'REQUIRED'
	| 'PHONE_NUMBER'
	| 'EMAIL'
	| 'DATE'
	| 'NUMBER'
	| 'ZIP_CODE'
	| 'YEAR'
	| 'MINBEDS';
export const VALIDATIONS: Record<ValidationTypes, Validate> = {
	REQUIRED: {
		type: 'REQUIRED',
		errorMessage: 'This field is required',
	},
	PHONE_NUMBER: {
		type: 'PHONE_NUMBER',
		regex: /^(null|([+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6})?)$/,
		errorMessage: 'Input not valid, it is not a phone number',
	},
	EMAIL: {
		type: 'EMAIL',
		regex: '^$|null|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
		errorMessage: 'Input not valid, it is not a valid email',
	},
	DATE: {
		type: 'DATE',
		regex:
			/(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})?/,
		errorMessage: 'Input not valid, it is not a date in format MM/DD/YYYY',
	},
	NUMBER: {
		type: 'NUMBER',
		regex: /null|^[0-9]\d*$/,
		errorMessage: 'Please enter numeric value',
	},
	ZIP_CODE: {
		type: 'ZIP_CODE',
		regex: /null|^\d{5}(?:[-\s]\d{4})?$/,
		errorMessage: 'Invalid zip code. Please enter a valid 5-digit zip code.',
	},
	YEAR: {
		type: 'YEAR',
		regex: /null|^[0-9]{4}$/,
		errorMessage: 'Input not valid, it should be a 4-digit year',
	},
	MINBEDS: {
		type: 'NUMBER',
		regex: /^(?!0$)\d+$/,
		errorMessage: 'Please enter a value greater than 0',
	},
};

export const arrayValidations = Object.values(VALIDATIONS);
