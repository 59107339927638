/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ExtendedFileModel } from '@models/file.model';
import { FileUploadService } from '@services/file-upload.service';
import {
	radioRowQuestionsFake,
	dropdownOptionsFake,
	checkboxOptionsFake,
} from 'src/app/constants/kitchen';
import { RadioRowQuestionType } from 'src/app/types/fields/radio-button';

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}
@Component({
	selector: 'app-kitchen',
	templateUrl: './kitchen.component.html',
	styleUrls: ['./kitchen.component.scss'],
})
export class KitchenComponent {
	files: File[] = [];
	uploadList: ExtendedFileModel[] = [];
	// TODO: Example URL to see the progress bar, replace with correct API Url
	apiUrl = 'https://v2.convertapi.com/upload';
	lastIndexFile = 0;
	radioRowQuestions = radioRowQuestionsFake;
	dropdownOptions = dropdownOptionsFake;
	checkboxOption = checkboxOptionsFake;
	myForm = new FormGroup({
		name: new FormControl('', [Validators.required, Validators.minLength(10)]),
		email: new FormControl('', [Validators.required, Validators.email]),
		date: new FormControl('', [Validators.required]),
		role: new FormControl('', [Validators.required]),
		multipleRole: new FormControl('', [Validators.required]),
		payors: new FormGroup(
			{
				['item-1']: new FormControl(),
				['item-2']: new FormControl(),
				['item-3']: new FormControl(),
			},
			[Validators.required]
		),
	});

	matcher = new MyErrorStateMatcher();
	nameMatcher = new MyErrorStateMatcher();
	dateMatcher = new MyErrorStateMatcher();
	roleMatcher = new MyErrorStateMatcher();

	constructor(private fileUploadService: FileUploadService) {}

	onFileChanged(file: File) {
		this.constructToUploadFile(file);
		this.uploadFile();
	}

	uploadFile(): void {
		const request = this.constructRequestChain();
		this.executeFileUpload(request);
	}

	private constructToUploadFile(file: File): void {
		const newFile: ExtendedFileModel = {
			file: file,
			uploadUrl: this.apiUrl,
			uploadStatus: {
				isSucess: false,
				isError: false,
				errorMessage: '',
				progressCount: 0,
				previewUrl: '',
				attachmentID: '',
			},
		};

		this.uploadList.push(newFile);
	}

	private constructRequestChain(): any {
		return this.fileUploadService
			.uploadFiles(this.uploadList[this.lastIndexFile])
			.pipe(
				tap(event => {
					if (event.type === HttpEventType.UploadProgress) {
						this.uploadList[this.lastIndexFile].uploadStatus.progressCount =
							Math.round(100 * (event.loaded / event.total));
					}
				}),
				catchError(error => {
					return of({ isError: true, error });
				})
			);
	}

	private executeFileUpload(request: any): void {
		request.subscribe((response: any) => {
			// TODO: Modify access to the response depending on our needs
			if (response.isError) {
				this.uploadList[this.lastIndexFile].uploadStatus.isError = true;
				this.uploadList[this.lastIndexFile].uploadStatus.errorMessage =
					response.error.statusText;
			}

			if ((response.ok || response.status === 200) && response.body) {
				this.uploadList[this.lastIndexFile].uploadStatus.isSucess = true;
				this.uploadList[this.lastIndexFile].uploadStatus.previewUrl =
					response.body.fileUrl;
				this.uploadList[this.lastIndexFile].uploadStatus.attachmentID =
					response.body.attachmentID;
				this.lastIndexFile += 1;
			}
		});
	}

	onRemoveFile(currentFileId: string) {
		this.fileUploadService.removeFile(currentFileId).subscribe({
			next: () => {
				console.log('File removed successfully');
			},
			error: err => {
				console.error('Error removing file:', err);
			},
			complete: () => {
				console.log('File removal process completed');
				const fileIndex = this.uploadList.findIndex(
					(item: ExtendedFileModel) =>
						item.uploadStatus.attachmentID === currentFileId
				);

				if (fileIndex !== -1) {
					this.uploadList.splice(fileIndex, 1); // Remove the item from uploadList
					this.lastIndexFile -= 1;
				}
			},
		});
	}

	get currentFile(): ExtendedFileModel | undefined {
		if (this.uploadList.length === 0) {
			return;
		}

		return this.uploadList[this.lastIndexFile];
	}

	onChangeRadioOption(selectedItem: RadioRowQuestionType) {
		alert(JSON.stringify(selectedItem));
	}
	getEmailErrorMessage() {
		const emailControl = this.myForm.get('email');
		if (emailControl?.hasError('email')) {
			return 'Please enter a valid email address';
		}
		return 'Please enter a value';
	}
	getDateErrorMessage() {
		return 'Please enter a value';
	}

	getNameErrorMessage() {
		const nameControl = this.myForm.get('name');
		if (nameControl?.hasError('minlength')) {
			return `Min lenght ${10}`;
		}
		return 'Please enter a value';
	}

	getRoleErrorMessage() {
		return 'Please chose one';
	}

	onSubmit() {
		console.log('Submit');
	}

	getFormControl(name: string) {
		return this.myForm.get(name) as FormControl;
	}
}
