import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

interface ExtendedValidatorConfig {
	pattern?: string | RegExp;
	required?: boolean;
	maxLength?: number;
	minLength?: number;
	message: string;
}

export function extendedValidator(
	config: ExtendedValidatorConfig
): ValidatorFn {
	return (control: AbstractControl): { [key: string]: unknown } | null => {
		if (config.required && Validators.required(control) !== null) {
			return { required: { message: config.message } };
		}

		if (config.pattern) {
			const pattern =
				config.pattern instanceof RegExp
					? config.pattern
					: new RegExp(config.pattern);

			if (!pattern.test(control.value)) {
				return { pattern: { message: config.message } };
			}
		}

		return null;
	};
}
