<div class="container">
	<mat-dialog-content class="content">
		<div class="dialog-header">
			<h1 class="truncate-text">{{ title }}</h1>
			<button
				mat-icon-button
				[mat-dialog-close]="'Close'"
				(click)="onCloseForm()">
				<mat-icon aria-label="Close dialog">close</mat-icon>
			</button>
		</div>
		<p>{{ message }}</p>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button
			mat-raised-button
			color="primary"
			[mat-dialog-close]="customData"
			(click)="onConfirmClick()">
			{{ doneButtonText }}
		</button>
		<button mat-stroked-button color="primary" mat-dialog-close>
			{{ cancelButtonText }}
		</button>
	</mat-dialog-actions>
</div>
