<div *ngIf="showHeader" class="inline-dynamic-container">
	<mat-label *ngIf="title" class="mat-body-1 title">{{ title }}</mat-label>
	<div *ngIf="!title" class="space title"></div>

	<div *ngIf="options" class="options">
		<mat-label
			*ngFor="let option of options; trackBy: trackByFn"
			class="mat-body-1"
			>{{ showInitials(option.label) }}</mat-label
		>
	</div>

	<div *ngIf="fields" class="options">
		<mat-label
			*ngFor="let field of fields; trackBy: trackByFn"
			class="mat-body-1"
			>{{ showInitials(field.label) }}</mat-label
		>
	</div>
</div>

<div
	class="inline-dynamic-container"
	[ngClass]="{ blue: index % 2 !== 0, gray: index % 2 === 0 }">
	<mat-label class="mat-body-1">{{ label }}</mat-label>

	<!-- Single Checkbox Group -->
	<section *ngIf="options" class="fields" [formGroup]="form">
		<mat-checkbox
			*ngFor="let option of options; trackBy: trackByFn"
			[formControlName]="option.value"
			color="primary"
			class="mat-checkbox-button-base" />
	</section>

	<!-- Fields Group -->
	<div *ngIf="fields" class="fields" [formGroup]="form">
		<app-text-input
			*ngFor="let field of fields; trackBy: trackByFn"
			[form]="form"
			[inputId]="field.name"
			[name]="field.name"
			[type]="field.type"
			[placeholder]="field.placeholder || ''"
			[hideHint]="true" />
	</div>
</div>
