import { QuestionSection } from './fields';
import { OrgansType, StatusType } from './general';

export type SurveyItem = {
	status?: SURVEY_STATUS;
	type?: 'question' | 'button';
	startIcon?: string;
	endIcon?: string;
	items?: SurveyItem[];
} & QuestionSection;
export type StatusIconType = { isSvg: boolean; name: string };
export enum SURVEY_STATUS {
	UNKNOWN = 'UNKNOWN',
	COMPLETE = 'COMPLETE',
	INCOMPLETE = 'INCOMPLETE',
}
export type SurveyItemId = string | number;
export enum SURVEY_FILTER_TYPES {
	ALL = 'all',
	NEW = 'new',
	IN_PROGRESS = 'inProgress',
	SUBMITTED = 'submitted',
}
export type SurveyEntryType = 'Hospital' | 'Payor' | 'Program' | 'Team';
export type Survey = {
	id: string | number;
	lastUpdated: string | null;
	year: string | number;
	progress: number;
	status: StatusType;
	generatedName: string;
	entryType: SurveyEntryType;
	entrySubType: OrgansType;
	programId?: number | null;
	payorId?: number | null;
	hospitalId?: number | null;
	timeAgo: '2 hours ago';
};

export type ErrorsType = { error: object; key: string };
