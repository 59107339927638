<mat-toolbar class="header">
	<a mat-button [href]="dashboardRoute" [disabled]="disableHeader">
		<img class="logo" src="assets/images/logo.png" alt="UNOS RFI" />
	</a>
	<span class="spacer"></span>
	<button
		[matMenuTriggerFor]="menu"
		class="btn-profile">
		<mat-icon svgIcon="person" class="person-icon">person</mat-icon>
		<mat-icon class="material-icons-outlined">expand_more</mat-icon>
	</button>

	<mat-menu #menu="matMenu" class="custom-menu">
		<button mat-menu-item (click)="openQuestions()">
			<mat-icon class="material-symbols-outlined">lists</mat-icon>
			<span>2025 UNOS RFI Questions</span>
		</button>
		<button mat-menu-item (click)="openGuide()">
			<mat-icon class="material-icons-outlined">info</mat-icon>
			<span>User Guide</span>
		</button>
		<button mat-menu-item (click)="logout()" [disabled]="disableHeader">
			<mat-icon class="logout-icon">logout</mat-icon>
			<span>Logout</span>
		</button>
	</mat-menu>
</mat-toolbar>
