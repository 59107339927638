<div class="radio-single" [formGroup]="form">
	<mat-label class="mat-body-1"
		>{{ label
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>
	<mat-radio-group color="primary" [formControlName]="name">
		<mat-radio-button
			*ngFor="let option of options"
			class="mat-radio-button-base"
			[value]="option.value"
			>{{ option.label }}</mat-radio-button
		>
	</mat-radio-group>
	<mat-hint>{{ assistiveText }}</mat-hint>
	<mat-error *ngIf="form.controls?.[name]?.errors?.['required']"
		>This field is required</mat-error
	>
</div>
