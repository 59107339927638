<mat-accordion
	multi
	*ngFor="let member of subsections; let index = index; trackBy: trackByFn">
	<mat-expansion-panel
		class="mat-elevation-z0 remove-mat-expansion-panel-padding team-accordion-title"
		togglePosition="before"
		[expanded]="member.id === (selectedTeamMember$ | async)?.id"
		(opened)="openTeamMember(member)">
		<mat-expansion-panel-header [ngClass]="{ 'container-header': index === 0 }">
			<div>
				<mat-panel-title class="mat-headline-5">
					{{form.value?.[index] | teamMemberName: index}}
				</mat-panel-title>
			</div>
		</mat-expansion-panel-header>

		<app-form
			*ngIf="member.type === 'teamMemberComponent'"
			[form]="getCurrentSection(index)"
			[item]="member"
			[memberId]="member.id" />

		<mat-accordion
			multi
			class="mat-mb-4 program-accordion"
			*ngFor="
				let program of member.subsection;
				let programIndex = index;
				trackBy: trackByFn
			">
			<mat-expansion-panel
				togglePosition="before"
				class="mat-elevation-z0 remove-mat-expansion-panel-padding program-accordion-title"
				[expanded]="program.id === (selectedTeamMemberProgam$ | async)?.id"
				(opened)="openTeamMemberProgam(program)">
				<mat-expansion-panel-header>
					<mat-panel-title class="mat-headline-5">Program #{{ programIndex + 1 }}</mat-panel-title>
				</mat-expansion-panel-header>
				<app-form
					*ngIf="program.type === 'teamProgramComponent'"
					[form]="getCurrentProgramSection(index, programIndex)"
					[item]="program"
					[programId]="program.id" />

				<button
					*ngIf="!isDisabledForm"
					class="remove-program-button"
					color="warn"
					type="button"
					[class.spinner-delete]="
						getIsDeletingProgramToTeamMemberLoading(program)
					"
					[disabled]="getIsDeletingProgramToTeamMemberLoading(program)"
					(click)="
						removeProgramFromTeamMember({
							member,
							memberIndex: index,
							program,
							programIndex
						})
					"
					mat-stroked-button>
					Remove program
					<mat-icon iconPositionEnd>delete</mat-icon>
				</button>
			</mat-expansion-panel>
		</mat-accordion>
		<button
			*ngIf="!isDisabledForm"
			class="program-button"
			color="primary"
			type="button"
			[class.spinner-add]="getIsAddingProgramToTeamMemberLoading(member)"
			[disabled]="getIsAddingProgramToTeamMemberLoading(member)"
			(click)="addProgramToTeamMember({ member, memberIndex: index })"
			mat-stroked-button>
			Add program
			<mat-icon iconPositionEnd>add</mat-icon>
		</button>
		<br />
		<button
			*ngIf="index > 0 && !isDisabledForm"
			class="remove-program-button"
			color="warn"
			type="button"
			(click)="removeTeamMember({ member, memberIndex: index })"
			mat-stroked-button
			[class.spinner-delete]="getIsDeletingTeamMemberLoading(member)"
			[disabled]="getIsDeletingTeamMemberLoading(member)">
			Remove team member
			<mat-icon iconPositionEnd>delete</mat-icon>
		</button>
	</mat-expansion-panel>
	<div class="divider"></div>
</mat-accordion>

<button
	*ngIf="!isDisabledForm"
	class="full-width-button role-button"
	type="button"
	[class.spinner-add]="loadingTeamMember"
	[disabled]="loadingTeamMember"
	mat-raised-button
	(click)="addTeamMember()">
	Add new member
	<mat-icon iconPositionEnd svgIcon="add-group-svg"></mat-icon>
</button>
