import { Survey } from '../types/survey';

export const getDateForSurvey = (survey: Survey) => {
	let prefix = 'Added';
	if (!survey.timeAgo) {
		return '';
	}

	if (survey.status === 'In Progress') {
		prefix = 'Last edit';
	}
	if (survey.status === 'Submitted') {
		prefix = 'Finished';
	}

	return `${prefix} ${survey.timeAgo}`;
};

export const getYearMinusInput = (input = 0) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() - input);
	return date.getFullYear().toString();
};
