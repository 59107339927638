import { getYearMinusInput } from 'src/app/utils/date';
import { STATES, SUBMIT_SECTION, titleOptions, yesNoOptions } from './commons';
import { FormEntryType } from './index';
import { VALIDATIONS } from './validations';

export const PROGRAM_SCHEMA: FormEntryType = {
	formTitle: 'Program Survey',
	formDescription:
		'One record available per institution. Data pre-seeded allowing the user to open forms for those institutions they have authority to access and continue to input information. Ideally, ability to collapse sections for ease in navigation through the form.',
	sections: [
		{
			title: 'Introduction',
			fields: [
				{
					label:
						'Please review with your medical director and surgical director before submitting.',
					type: 'paragraph',
					name: '',
					style: 'remove-mb',
				},
				{
					label: 'Person completing form for this program',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Title',
					type: 'select',
					name: 'submitterTitle',
					placeholder: 'Select title',
					colSpan: 4,
					options: titleOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'First Name',
					type: 'text',
					name: 'submitterFirstName',
					placeholder: 'Enter your first name',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Last Name',
					type: 'text',
					name: 'submitterLastName',
					placeholder: 'Enter your last name',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Street Address',
					type: 'text',
					name: 'submitterAddress',
					placeholder: 'Enter the street address',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Street Address (continued)',
					type: 'text',
					name: 'submitterAddress2',
					placeholder: 'Enter the address',
					colSpan: 6,
				},
				{
					label: 'City',
					type: 'text',
					name: 'submitterCity',
					placeholder: 'Enter the city',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'State',
					type: 'select',
					name: 'submitterState',
					placeholder: 'Enter the state',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
					options: STATES,
				},
				{
					label: 'Zip code',
					type: 'text',
					name: 'submitterZip',
					placeholder: 'Enter the zip code',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.ZIP_CODE],
				},
				{
					label: 'Telephone',
					type: 'text',
					name: 'submitterPhone',
					placeholder: 'Enter the telephone number',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.PHONE_NUMBER],
				},
				{
					label: 'Email Address',
					type: 'text',
					name: 'submitterEmail',
					placeholder: 'Enter the email address',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.EMAIL],
				},
				{
					label: 'Transplant Contract Manager',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'First Name',
					type: 'text',
					name: 'managerFirstName',
					placeholder: 'Enter your first name',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Last Name',
					type: 'text',
					name: 'managerLastName',
					placeholder: 'Enter your last name',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Street Address',
					type: 'text',
					name: 'managerAddress',
					placeholder: 'Enter the street address',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},

				{
					label: 'Street Address (continued)',
					type: 'text',
					name: 'managerAddress2',
					placeholder: 'Enter the address',
					colSpan: 6,
				},
				{
					label: 'City',
					type: 'text',
					name: 'managerCity',
					placeholder: 'Enter the city',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'State',
					type: 'select',
					name: 'managerState',
					placeholder: 'Enter the state',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED],
					options: STATES,
				},
				{
					label: 'Zip code',
					type: 'text',
					name: 'managerZip',
					placeholder: 'Enter the zip code',
					colSpan: 4,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.ZIP_CODE],
				},
				{
					label: 'Telephone',
					type: 'text',
					name: 'managerPhone',
					placeholder: 'Enter the telephone number',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.PHONE_NUMBER],
				},
				{
					label: 'Email Address',
					type: 'text',
					name: 'managerEmail',
					placeholder: 'Enter the email address',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.EMAIL],
				},
			],
		},
		{
			title: 'General Information',
			fields: [
				{
					label: 'CMS Transplant Certification',
					type: 'radio',
					name: 'medicareAccreditation',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Most recent CMS certification (or recertification) date',
					type: 'date',
					name: 'medicareAccrDate',
					placeholder: 'CMS certification date',
					colSpan: 6,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'medicareAccreditation',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'OPTN Accreditation',
					type: 'radio',
					name: 'optnAccreditation',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'OPTN Accreditation Date',
					type: 'date',
					name: 'optnAccreditationDate',
					placeholder: 'Enter OPTN Accreditation date',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'optnAccreditation',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Have there been any changes in CMS or OPTN status?',
					type: 'radio',
					name: 'changesInStatus',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If yes, please explain',
					type: 'textarea',
					name: 'changesInStatusExplanation',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'changesInStatus',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Is your institution accredited by a CMS approved accreditation body?',
					type: 'radio',
					name: 'cmsApprovedAccreditation',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Accreditation Date',
					type: 'date',
					name: 'accreditationDate',
					placeholder: 'Enter Accreditation date',
					colSpan: 6,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'cmsApprovedAccreditation',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Name of Accreditation Body',
					type: 'text',
					name: 'accreditationBody',
					placeholder: 'Enter Accreditation Body',
					colSpan: 6,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'cmsApprovedAccreditation',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Date of first adult transplant',
					bigLabel: true,
					type: 'date',
					name: 'adultTXPDate',
					placeholder: 'Enter date',
					colSpan: 6,
				},
				{
					label: 'Date of first pediatric transplant',
					bigLabel: true,
					type: 'date',
					name: 'pediatricTXPDate',
					placeholder: 'Enter date',
					colSpan: 6,
				},
				{
					label:
						'Has the pediatric heart transplant program performed transplants on children under the age of five in the last three years?',
					type: 'radio',
					name: 'pediatricTXPRecent',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['heart'],
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'If yes, please provide the total number of congenital heart surgeries performed including pediatrics and adults in the last three years',
					type: 'paragraph-body',
					name: '',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'pediatricTXPRecent',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label: getYearMinusInput(1),
					type: 'number',
					name: 'txpYearMinusOne',
					colSpan: 4,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'pediatricTXPRecent',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: getYearMinusInput(2),
					type: 'number',
					name: 'txpYearMinusTwo',
					colSpan: 4,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'pediatricTXPRecent',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: getYearMinusInput(3),
					type: 'number',
					name: 'txpYearMinusThree',
					colSpan: 4,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'pediatricTXPRecent',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Date of first living donor adult transplant',
					bigLabel: true,
					type: 'date',
					name: 'adultLivingTXPDate',
					placeholder: 'Enter date',
					colSpan: 6,
					showIn: ['kidney', 'liver'],
				},
				{
					label: 'Date of first living donor pediatric transplant',
					bigLabel: true,
					type: 'date',
					name: 'pediatricLivingTXPDate',
					placeholder: 'Enter date',
					colSpan: 6,
					showIn: ['kidney', 'liver'],
				},
				{
					label:
						'Has the program been closed or suspended for any reason during the last 10 years?',
					type: 'radio',
					name: 'programSuspended',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If yes, please provide closure/suspension date (mm/dd/yyyy)',
					type: 'date',
					name: 'programSuspensionDate',
					placeholder: 'Enter date',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'programSuspended',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'If yes, please explain',
					type: 'textarea',
					name: 'programSuspensionExplanation',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'programSuspended',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
			],
		},
		{
			title: 'Guidelines/Protocols',
			fields: [
				{
					label:
						'Describe the criteria for patient selection, including indications or contraindications',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'patientSelectionCriteria',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'patientSelectionAttachments',
					colSpan: 12,
				},
				{
					type: 'divider',
					name: '',
					label: '',
					showIn: ['kidney', 'liver'],
				},
				{
					label: 'Describe the guidelines/protocols for living donor selection',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'livingDonorSelectionProtocol',
					colSpan: 12,
					showIn: ['kidney', 'liver'],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'livingDonorSelectionAttachments',
					colSpan: 12,
					showIn: ['kidney', 'liver'],
				},
				{
					type: 'divider',
					name: '',
					label: '',
				},
				{
					label:
						"Describe center's criteria for rejection identification and treatment",
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'rejectionCriteria',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'rejectionCriteriaAttachments',
					colSpan: 12,
				},
				{
					type: 'divider',
					name: '',
					label: '',
				},
				{
					label:
						'Describe pre and post-transplant support services (education, social services, financial counseling, etc.)',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'prePostTransplantServices',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'prePostServicesAttachments',
					colSpan: 12,
				},
				{
					type: 'divider',
					name: '',
					label: '',
				},
				{
					label:
						'Describe pre and post-transplant patient education (at a minimum, include details of pre-transplant information provided to patients including: waiting time, post-transplant medication, education, etc.)',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'prePostTransplantEducation',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'prePostEduAttachments',
					colSpan: 12,
				},
				{
					type: 'divider',
					name: '',
					label: '',
				},
				{
					label: 'Does your program utilize machine preservation?',
					type: 'radio',
					name: 'machinePreservationProtocol',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['kidney', 'liver', 'heart', 'lung'],
				},
				{
					label: 'If yes, please provide the protocol.',
					type: 'textarea',
					name: 'machineProtocolExplanation',
					colSpan: 12,
					showIn: ['kidney', 'liver', 'heart', 'lung'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'machinePreservationProtocol',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					type: 'file',
					name: 'machinePreservationAttachments',
					colSpan: 12,
					showIn: ['kidney', 'liver', 'heart', 'lung'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'machinePreservationProtocol',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label:
						'Does your program conduct a "Quality of Life" survey that is sent to recipients? ',
					type: 'radio',
					name: 'qualityOfLifeSurvey',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Does your program use a "Patient Satisfaction" survey that is sent to recipients?',
					type: 'radio',
					name: 'patientSatisfactionSurvey',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Does the program offer bio-artificial liver as a bridge to transplant?',
					type: 'radio',
					name: 'bioArtificialLiver',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['liver'],
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If yes, number done in last two calendar years',
					type: 'text',
					name: 'bioArtificialLiverCount',
					colSpan: 12,
					showIn: ['liver'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'bioArtificialLiver',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label: 'If yes, please provide the protocol.',
					type: 'textarea',
					name: 'bioArtificialLiverProtocol',
					colSpan: 12,
					showIn: ['liver'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'bioArtificialLiver',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'bioArtificialLiverAttachments',
					colSpan: 12,
					showIn: ['liver'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'bioArtificialLiver',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					type: 'divider',
					name: '',
					label: '',
				},
				{
					label:
						'Please provide a list of research protocols in which transplant patients may be enrolled.',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'researchProtocols',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'researchProtocolsAttachments',
					colSpan: 12,
				},
				{
					label:
						'For stand-alone pediatric facilities that perform living donor kidney/liver transplants, where is the donor explant/donor liver resection performed?  When performed at a different facility, which facility would report the donor complications?',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'donorExplantLocation',
					colSpan: 12,
					showIn: ['kidney', 'liver'],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'donorExplantAttachments',
					colSpan: 12,
					showIn: ['kidney', 'liver'],
				},
				{
					label:
						'Guidelines/protocols for Mechanical Circulatory Support (MCS) program if MCS used in outpatient setting.',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'mcsGuidelines',
					colSpan: 12,
					showIn: ['heart'],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'mcsGuidelinesAttachments',
					colSpan: 12,
					showIn: ['heart'],
				},
				{
					label:
						'Does your institution have an advanced heart failure program?',
					bigLabel: true,
					showRequired: true,
					type: 'radio',
					name: 'advancedHeartFailureProgram',
					options: yesNoOptions,
					colSpan: 12,
					showIn: ['heart'],
				},
				{
					label: 'If yes, please describe',
					type: 'textarea',
					name: 'advancedHeartFailureProgramDesc',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'advancedHeartFailureProgram',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'advancedHeartFailureAttachments',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'advancedHeartFailureProgram',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label: 'Does the program offer Flolan therapy?',
					bigLabel: true,
					showRequired: true,
					type: 'radio',
					name: 'flolanTherapy',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['lung'],
				},
				{
					label: 'If yes, please describe',
					type: 'textarea',
					name: 'flolanProtocol',
					colSpan: 12,
					showIn: ['lung'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'flolanTherapy',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'flolanProtocolAttachments',
					colSpan: 12,
					showIn: ['lung'],
				},
				{
					label: 'How does your program assess frailty?',
					bigLabel: true,
					type: 'textarea',
					name: 'frailtyAssessmentMethod',
					colSpan: 12,
				},
				{
					label:
						'Do you have a maximum distance you allow patients to live from hospital while listed?',
					type: 'radio',
					name: 'distanceWhileListed',
					options: yesNoOptions,
					colSpan: 12,
				},
				{
					label: 'If yes, what is the maximum distance?',
					type: 'text',
					name: 'maxDistanceWhileListed',
					placeholder: 'Mi',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'distanceWhileListed',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Do you have a maximum distance you allow patients to live from hospital post-transplant?',
					type: 'radio',
					name: 'distancePostTransplant',
					options: yesNoOptions,
					colSpan: 12,
				},
				{
					label: 'If yes, what is the maximum distance?',
					type: 'number',
					placeholder: 'Mi',
					name: 'maxDistancePostTransplant',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'distancePostTransplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'For how many months?',
					type: 'number',
					name: 'postTransplantMonths',
					placeholder: '00',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'distancePostTransplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Which EMR system are you using?',
					bigLabel: true,
					type: 'text',
					name: 'emrSystem',
					placeholder: 'EMR system',
					colSpan: 12,
				},
				{
					label: 'Do you have a transplant specific module? ',
					smallLabel: true,
					type: 'radio',
					name: 'errModule',
					colSpan: 12,
					options: yesNoOptions,
				},
				{
					label:
						'Do you work with designated LTAC, SNF, and home care providers?',
					type: 'radio',
					name: 'homeCare',
					colSpan: 12,
					options: yesNoOptions,
				},
			],
		},
		{
			title: 'Quality',
			fields: [
				{
					label:
						'Describe actual quality improvements made over the previous year.',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'qualityImprovements',
					colSpan: 12,
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'qualityImprovementsAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Summary',
			fields: [
				{
					label:
						"Describe the program's unique qualities (Please limit to items most other centers don't have, something that is innovative. What would you want a patient to know about your program?)",
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'uniqueQualities',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'uniqueQualitiesAttachments',
					colSpan: 12,
				},
				{
					label:
						'Provide any other information not detailed anywhere else in the survey that you feel is important regarding your program',
					bigLabel: true,
					showRequired: true,
					type: 'textarea',
					name: 'additionalInformation',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'additionalInfoAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Experience Data',
			fields: [
				{
					label:
						'Did the program experience any significant changes in survival rates or other experience outcomes in the past three years that led you to some sort of action/quality project to address it?',
					type: 'radio',
					name: 'changesInPastThreeYear',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If yes, please explain',
					type: 'textarea',
					name: 'changesExplanation',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'changesInPastThreeYear',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Note: If more attachments are needed, an additional field will open once this one is completed.',
					assistiveText:
						'Please provide an answer by adding text and/or uploading an attachment',
					assistiveTextClass: 'red-text',
					type: 'file',
					name: 'changesAttachments',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'changesInPastThreeYear',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Long term Mechanical Circulatory Support Devices (MCS) Program',
			showIn: ['heart'],
			fields: [
				{
					label:
						'Does your transplant center implant long term mechanical circulatory support devices?',
					type: 'radio',
					name: 'mcsImplant',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['heart'],
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: getYearMinusInput(1),
					type: 'paragraph',
					name: '',
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label:
						'How many patients were referred to your center for advanced heart failure evaluation/therapy?',
					type: 'number',
					name: 'mcsVolTherapy1',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'How many patients received an LVAD (Include Bridge and Destination therapy)?',
					type: 'number',
					name: 'mcsVolLVAD1',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of heart transplants performed at your center?',
					type: 'number',
					name: 'mcsVolTotalHRTX1',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Total number of transplants that had an LVAD present at time of transplant?',
					type: 'number',
					name: 'mcsVolTotalTXLVAD1',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of TAH implanted?',
					type: 'number',
					name: 'mcsVolTotalTAH1',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: getYearMinusInput(2),
					type: 'paragraph',
					name: '',
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label:
						'How many patients were referred to your center for advanced heart failure evaluation/therapy?',
					type: 'number',
					name: 'mcsVolTherapy2',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'How many patients received an LVAD (Include Bridge and Destination therapy)?',
					type: 'number',
					name: 'mcsVolLVAD2',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of heart transplants performed at your center?',
					type: 'number',
					name: 'mcsVolTotalHRTX2',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Total number of transplants that had an LVAD present at time of transplant?',
					type: 'number',
					name: 'mcsVolTotalTXLVAD2',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of TAH implanted?',
					type: 'number',
					name: 'mcsVolTotalTAH2',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: getYearMinusInput(3),
					type: 'paragraph',
					name: '',
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
				{
					label:
						'How many patients were referred to your center for advanced heart failure evaluation/therapy?',
					type: 'number',
					name: 'mcsVolTherapy3',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'How many patients received an LVAD (Include Bridge and Destination therapy)?',
					type: 'number',
					name: 'mcsVolLVAD3',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of heart transplants performed at your center?',
					type: 'number',
					name: 'mcsVolTotalHRTX3',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Total number of transplants that had an LVAD present at time of transplant?',
					type: 'number',
					name: 'mcsVolTotalTXLVAD3',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Total number of TAH implanted?',
					type: 'number',
					name: 'mcsVolTotalTAH3',
					colSpan: 12,
					showIn: ['heart'],
					validate: [
						{
							dependsOn: [
								{
									questionName: 'mcsImplant',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Does your adult program possess the Joint Commission Advanced Certification in Disease-Specific Care to implant LVADs as "Destination Therapy"?',
					type: 'radio',
					name: 'jointCommissionCert',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['heart'],
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Does your Mechanical Circulatory Support (MCS) program have CMS designation?',
					type: 'radio',
					name: 'mcsProgramCMS',
					colSpan: 12,
					options: yesNoOptions,
					showIn: ['heart'],
					validate: [VALIDATIONS.REQUIRED],
				},
			],
		},
		SUBMIT_SECTION,
	],
};
