import { Field } from '../types/fields';

export const TEAM_MEMBER_PROGRAM_NAME = 'programId';
export const TEAM_MEMBER_ROLE_NAME = 'roleId';

export const PROGRAM_INPUTS_NAME = [
	'teamContactPrograms',
	TEAM_MEMBER_PROGRAM_NAME,
];

export const isProgramField = (field: Field) => {
	return PROGRAM_INPUTS_NAME.some(item => field.name.includes(item));
};

export const isTeamMemberProgramField = (field: Field) => {
	return [TEAM_MEMBER_PROGRAM_NAME].some(item => field.name.includes(item));
};

export const isRoleField = (field: Field) => {
	return [TEAM_MEMBER_ROLE_NAME].some(item => field.name.includes(item));
};

export const NOT_ADMIN_ROLES = ['1', '2', '3', '4'];
export const SURGEON_ROLES = ['1', '3'];

export const LIMITER = '$$';
