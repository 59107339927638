import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamMemberName'
})
export class TeamMemberNamePipe implements PipeTransform {

	transform(value: any, index: number): string {
		const { firstName, lastName } = value || {};
		if (firstName && lastName) {
			return `${firstName} ${lastName}`.trim();
		}
		return `Team Member #${index + 1}`;
	}

}
