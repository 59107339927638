import {
	CATEGORY_OPTIONS_NONE,
	STATES,
	SUBMIT_SECTION,
	yesNoOptions,
} from './commons';
import { FormEntryType } from './index';
import { VALIDATIONS } from './validations';

export const HOSPITAL_SCHEMA: FormEntryType = {
	formTitle: 'Hospital Form',
	formDescription:
		'One record available per institution. Data pre-seeded allowing the user to open forms for those institutions they have authority to access and continue to input information. Ideally, ability to collapse sections for ease in navigation through the form.',
	sections: [
		{
			title: 'Hospital Information',
			fields: [
				{
					label:
						'Provide the legal name of the institution or corporation responsible for the provision of transplant services',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Name',
					type: 'text',
					name: 'institutionName',
					placeholder: 'Enter the institution name',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Street Address',
					type: 'text',
					name: 'institutionAddress',
					placeholder: 'Enter the street address',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Apartment, Unit, etc.',
					type: 'text',
					name: 'institutionAddress2',
					placeholder: 'Apartment',
					colSpan: 6,
				},
				{
					label: 'City',
					type: 'text',
					name: 'institutionCity',
					placeholder: 'Enter the city',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'State',
					type: 'select',
					name: 'institutionState',
					placeholder: 'Enter the state',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
					options: STATES,
				},
				{
					label: 'Zip code',
					type: 'text',
					name: 'institutionZip',
					placeholder: 'Enter the zip code',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.ZIP_CODE],
				},
				{
					label: 'Hospital Tax ID Number',
					type: 'text',
					name: 'hospitalTaxID',
					placeholder: 'Enter the Tax ID number',
					colSpan: 4,
				},
				{
					label: 'Chief Executive Officer',
					type: 'text',
					name: 'ceoName',
					placeholder: "Enter the CEO's name",
					colSpan: 4,
				},
				{
					label: 'Telephone',
					type: 'tel',
					name: 'telephone',
					placeholder: 'Enter the telephone number',
					colSpan: 4,
					validate: [VALIDATIONS.PHONE_NUMBER],
				},
				{
					label:
						"Is your institution currently licensed by CMS or the State's regulatory agencies?",
					type: 'radio',
					name: 'isLicensedByState',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If not, explain:',
					type: 'textarea',
					name: 'explanationForNoLicense',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'isLicensedByState',
									questionValue: '0',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						"On what date does your CMS/State's license expire or explain with a short statement?",
					type: 'textarea',
					name: 'explanationForLicenseByState',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'isLicensedByState',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Are there conditions on the current federal, state, or local licenses, permits, or certifications?',
					type: 'radio',
					name: 'conditionsOnLicenses',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If yes, explain',
					type: 'textarea',
					name: 'explanationForConditions',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'conditionsOnLicenses',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
			],
		},
		{
			title: 'Affiliations and Credentials',
			fields: [
				{
					label:
						'Is your institution affiliated with or the parent corporation of other hospitals/institutions?',
					type: 'radio',
					name: 'affiliatedWithOtherHospitals',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'If yes, what is the name(s) of the affiliated institutions and the nature of the relationship?',
					type: 'textarea',
					name: 'affiliatedInstitutions',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'affiliatedWithOtherHospitals',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Are any pre-transplant episode or post-transplant services (clinic visit, evaluation, major diagnostic testing, etc.) being provided at the affiliated institutions listed in question above?',
					type: 'radio',
					name: 'servicesAtAffiliatedInstitutions',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'If yes, please list which affiliate and which type of service.',
					type: 'textarea',
					name: 'servicesAtAffiliatedDetails',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'servicesAtAffiliatedInstitutions',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Is your institution accredited by the Joint Commission or equivalent?',
					type: 'radio',
					name: 'accreditedByJointCommission',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'If equivalent, name of equivalent accreditation organization',
					type: 'text',
					name: 'equivalentAccreditationOrganization',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'accreditedByJointCommission',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'If yes, are there any contingencies on the accreditation?',
					type: 'radio',
					name: 'contingenciesOnAccreditation',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'accreditedByJointCommission',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
					options: yesNoOptions,
				},
				{
					label: 'If yes, explain the contingencies.',
					type: 'textarea',
					name: 'explanationForContingencies',
					validate: [
						{
							dependsOn: [
								{
									questionName: 'contingenciesOnAccreditation',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
					colSpan: 12,
				},
				{
					label:
						'On what date does your TJC or equivalent accreditation expire?',
					type: 'date',
					name: 'accreditationExpiryDate',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'accreditedByJointCommission',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Does your institution’s Medical Staff Bylaws contain a comprehensive process for credentialing and re-credentialing of physicians participating in the organ transplant programs including primary verification of',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Licensure',
					type: 'radio',
					style: 'inline',
					name: 'verificationOfLicensure',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Previous Appointments',
					type: 'radio',
					style: 'inline',
					name: 'verificationOfPrevAppointment',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'DEA Certificates',
					type: 'radio',
					style: 'inline',
					name: 'verificationOfDEACertificates',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Previous State Medicare/Medicaid Sanctions',
					type: 'radio',
					style: 'inline',
					name: 'verificationOfPrevMedicare',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'The National Practitioner Data Bank',
					type: 'radio',
					style: 'inline',
					name: 'verificationOfDataBank',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'credentialAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Program Structure',
			fields: [
				{
					label: 'Transplant programs available at your institution.',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Blood and Bone Marrow: Allogenic Related',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMRel',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Blood and Bone Marrow: Allogenic Unrelated',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMUnrel',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Blood and Bone Marrow: Autologous',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMAuto',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Blood and Bone Marrow: Peripheral Blood Stem Cell',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMStem',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Blood and Bone Marrow: Cord Blood',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMCord',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label:
						'Blood and Bone Marrow: Autologous and/or Allogenic Tandem or Sequential',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramBMSeq',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Heart: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramHRDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Heart: Domino Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramHRDom',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Lung: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramLUDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Lung: Living Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramLULiv',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Intestine: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramINDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Islet Cell: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramISDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Islet Cell: Living Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramISLiv',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Islet Cell: Autografts',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramISAuto',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Kidney: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramKIDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Kidney: Living Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramKILiv',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Liver: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramLIDec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Liver: Living Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramLILiv',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Pancreas: Deceased Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramPADec',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Pancreas: Domino Donor',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramPADom',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Heart-Lung',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombHRLU',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Heart-Kidney',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombHRKI',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Islet-Kidney',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombISKI',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Kidney-Pancreas',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombKIPA',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Liver-Kidney',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombLIKI',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Liver-Pancreas',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombLIPA',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Combination: Liver-Intestine',
					type: 'radio',
					style: 'inline',
					name: 'transplantProgramCombLIIN',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
				},
				{
					label: 'Other',
					type: 'radio',
					style: 'input',
					name: 'transplantProgramOtherName',
					nameOption: 'transplantProgramOther',
					options: CATEGORY_OPTIONS_NONE,
					colSpan: 12,
					validateOption: [
						{
							dependsOn: [
								{
									questionName: 'transplantProgramOtherName',
									justFilling: true,
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label:
						'Please provide an organizational chart to show where each of the solid organ and bone marrow transplant programs fit in the reporting structure of the facility',
					bigLabel: true,
					type: 'file',
					name: 'orgChartAttachments',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'Name and address of immunology laboratory affiliated with transplant program: (State accreditation organization)',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Name',
					type: 'text',
					name: 'affImmunologyLabName',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Address',
					type: 'text',
					name: 'affImmunologyLabAddress',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'City',
					type: 'text',
					name: 'affImmunologyLabCity',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'State',
					type: 'select',
					name: 'affImmunologyLabState',
					options: STATES,
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Zip code',
					type: 'text',
					name: 'affImmunologyLabZip',
					colSpan: 6,
					validate: [VALIDATIONS.REQUIRED, VALIDATIONS.ZIP_CODE],
				},
				{
					label:
						'Facilities and services: Special Inpatient and Outpatient Facilities ',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'BMT',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesBMT',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesBMTBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesBMT',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Medical Intensive Care Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesMICU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesMICUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesMICU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Surgical Intensive Care Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesSICU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesSICUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesSICU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Neonatal Intensive Care Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesNICU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesNICUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesNICU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Pediatric Intensive Care Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesPICU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesPICUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesPICU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'General Pediatric Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesGPU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesGPUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesGPU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Cardiopulmonary/Thoracic Transplant Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesCTTU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesCTTUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesCTTU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Solid Organ Transplant Unit',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'facilitiesSOTU',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: '',
					type: 'number',
					name: 'facilitiesSOTUBeds',
					placeholder: 'Number of Beds',
					colSpan: 4,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'facilitiesSOTU',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.NUMBER,
						VALIDATIONS.MINBEDS,
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'BMT Clinic',
					type: 'radio',
					showRequired: true,
					style: 'inline-input',
					name: 'specialBMTC',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Medical and Surgical Transplant Clinics',
					type: 'radio',
					style: 'inline-input',
					name: 'specialMSTC',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Home Health Transplant Nursing Specialists',
					type: 'radio',
					style: 'inline-input',
					name: 'specialHTNS',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Pediatric cardiology or heart transplant clinic',
					type: 'radio',
					style: 'inline-input',
					name: 'specialPCTC',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Facilities and Services Available 24 hours/day, 7 days/week',
					type: 'paragraph',
					name: '',
				},
				{
					label: 'Anesthesiology',
					type: 'radio',
					style: 'inline',
					name: 'availAnesthesiology',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Pathology',
					type: 'radio',
					style: 'inline',
					name: 'availPathology',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Blood Banking',
					type: 'radio',
					style: 'inline',
					name: 'availBloodBanking',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Renal Dialysis',
					type: 'radio',
					style: 'inline',
					name: 'availRenalDialysis',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Cardiac catheterization and cardiac surgery',
					type: 'radio',
					style: 'inline',
					name: 'availCathCardiacSurgery',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Operating rooms',
					type: 'radio',
					style: 'inline',
					name: 'availOperatingRooms',
					options: yesNoOptions,
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
			],
		},
		{
			title: 'Accommodations',
			fields: [
				{
					label:
						'Are accommodations for living arrangements available for patient(s)/companion(s) for both pre and post-transplant period?',
					type: 'radio',
					name: 'prePostAccommodations',
					colSpan: 12,
					options: yesNoOptions,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label:
						'If yes, list and provide information including if transplant-specific',
					type: 'textarea',
					name: 'prePostAccommodationsDetails',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'prePostAccommodations',
									questionValue: '1',
								},
							],
						},
						VALIDATIONS.REQUIRED,
					],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'prePostAttachments',
					colSpan: 12,
					validate: [
						{
							dependsOn: [
								{
									questionName: 'prePostAccommodations',
									questionValue: '1',
									withoutValue: true,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Staff Trainings',
			fields: [
				{
					label:
						'What kind of ongoing training is provided for the transplant staff?  What kind of educational services are provided for the community physicians? I.e. conference, workshops, symposiums, CE calendar, weekly/monthly meetings, etc. Are continuing education credits offered? ',
					bigLabel: true,
					type: 'textarea',
					name: 'staffTraining',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'staffTrainingAttchments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Unique Qualities',
			fields: [
				{
					label:
						'Summarize the unique qualities that add value for patients, referring physicians and payers (attach additional copies as needed).  Include such information as ACGME programs, fellowship programs, key accomplishments, bloodless transplants, future directions.  If the institution supports a pediatric program please outline any special programs (i.e. Child Life Program).',
					bigLabel: true,
					type: 'textarea',
					name: 'uniqueQualities',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'uniqueQualitiesAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Cost Control',
			fields: [
				{
					label:
						'Summarize efforts to control costs associated with transplantation.',
					bigLabel: true,
					type: 'textarea',
					name: 'costControl',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'costControlAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Patient Safety Initiatives',
			fields: [
				{
					label:
						'Describe patient safety initiatives specific to transplant services.',
					bigLabel: true,
					type: 'textarea',
					name: 'patientSafety',
					colSpan: 12,
					validate: [VALIDATIONS.REQUIRED],
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'patientSafetyAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Quality Improvement Process',
			fields: [
				{
					label:
						"Describe or attach overview of the hospital's quality improvement process.  What is the relationship or interface between the transplant programs and the hospital QI/QM department? Attach transplant specific QAPI.",
					type: 'paragraph',
					name: '',
				},
				{
					label: '',
					type: 'textarea',
					name: 'qualityImprovements',
					colSpan: 12,
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'qualityImprovementsAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: 'Islet Program',
			fields: [
				{
					label:
						'If hospital performs autologous islet cell transplants and/or modified tissue therapy (ex. Rethymic) please provide any supportive information that describes your program.',
					bigLabel: true,
					type: 'textarea',
					name: 'isletProgram',
					colSpan: 12,
				},
				{
					label: 'Attachment if needed',
					type: 'file',
					name: 'isletProgramAttachments',
					colSpan: 12,
				},
			],
		},
		{
			title: SUBMIT_SECTION.title,
			fields: SUBMIT_SECTION.fields.slice(2),
		},
	],
};
