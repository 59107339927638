<mat-accordion
	multi
	*ngFor="let contact of subsections; let index = index; trackBy: trackByFn">
	<mat-expansion-panel
		class="mat-elevation-z0 remove-mat-expansion-panel-padding team-accordion-title"
		togglePosition="before"
		[expanded]="contact.id === (selectedContactMember$ | async)?.id"
		(opened)="openContactMember(contact)">
		<mat-expansion-panel-header>
			<mat-panel-title class="mat-headline-5">{{
				getContactName(index)
			}}</mat-panel-title>
		</mat-expansion-panel-header>
		<app-form [form]="getCurrentSection(index)" [item]="contact"></app-form>
		<button
			*ngIf="index > 0 && !isDisabledForm"
			class="remove-program-button"
			color="warn"
			type="button"
			[class.spinner-delete]="getIsDeletingContactTeamLoading(contact)"
			[disabled]="getIsDeletingContactTeamLoading(contact)"
			(click)="removecontact({ contact, contactIndex: index })"
			mat-stroked-button>
			Remove contact
			<mat-icon iconPositionEnd>delete</mat-icon>
		</button>
	</mat-expansion-panel>
	<div class="divider"></div>
</mat-accordion>
<button
	*ngIf="!isDisabledForm"
	class="full-width-button role-button"
	type="button"
	mat-raised-button
	[class.spinner-add]="loadingTeamContact"
	[disabled]="loadingTeamContact"
	(click)="addContact()">
	Add new contact
	<mat-icon iconPositionEnd svgIcon="add-group-svg"></mat-icon>
</button>
