import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { OptionType } from 'src/app/types/general';

@Component({
	selector: 'app-radio-box',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})
export class RadioBoxComponent {
	@Input() form!: FormGroup;
	@Input() name!: string;
	@Input() label = '';
	@Input() smallLabel = false;
	@Input() assistiveText = '';
	@Input() options: OptionType[] | undefined = [];
	@Input() isRequired!: boolean;
	@Input() errorMessage?: string;
	@Input() errorMatcher: ErrorStateMatcher = {
		isErrorState: function (): boolean {
			return false;
		},
	};
}
