<div *ngIf="showHeader" class="radio-inline-input-container header">
	<div class="space"></div>

	<div class="options">
		<mat-label *ngFor="let option of options" class="mat-body-1">{{
			option.label
		}}</mat-label>
	</div>

	<mat-label *ngIf="textInputField" class="mat-body-1"
		>{{ textInputField.placeholder
		}}<span *ngIf="isRequired" class="text-error required">*</span></mat-label
	>
</div>

<div
	class="radio-inline-input-container"
	[formGroup]="form"
	[ngClass]="{ blue: randomNumber % 2 !== 0, gray: randomNumber % 2 === 0 }">
	<mat-label class="mat-body-1"
		>{{ label }}<span *ngIf="isRequired" class="text-error required">*</span>
	</mat-label>

	<mat-radio-group
		color="primary"
		class="radio-group-YN"
		[formControlName]="name">
		<mat-radio-button
			*ngFor="let option of options"
			class="mat-radio-button-base"
			[value]="option.value" />
	</mat-radio-group>

	<!-- Text Input -->
	<div *ngIf="textInputField && showInput" class="input-container">
		<app-text-input
			[form]="form"
			[inputId]="textInputField.name"
			[name]="textInputField.name"
			[type]="textInputField.type"
			[label]="textInputField.label"
			[placeholder]="textInputField.placeholder || ''"
			[hideHint]="true"
			[errorMatcher]="matcher" />
	</div>

	<div *ngIf="!textInputField || !showInput" class="space"></div>
</div>
