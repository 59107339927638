/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Component,
	Input,
	Output,
	ElementRef,
	EventEmitter,
	OnInit,
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { SurveyStore } from 'src/app/stores/survey';
import { SURVEY_STATUS, SurveyItem, SurveyItemId } from 'src/app/types/survey';

@Component({
	selector: 'app-accordion-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
	@Input() index!: number;
	@Input() item!: SurveyItem;
	@Input() selectedId?: SurveyItemId;
	@Output() changeStepState = new EventEmitter<SURVEY_STATUS>();
	@Output() selectSurveyItem = new EventEmitter<SurveyItem>();

	sections$!: Observable<FormArray>;
	currentSection!: FormGroup;
	isOpen = false;
	status = SURVEY_STATUS;

	constructor(
		public elementRef: ElementRef,
		public store: SurveyStore
	) {}
	ngOnInit(): void {
		this.sections$ = this.store.select(state => {
			return state.parentForm.controls['sections'] as FormArray;
		});
		this.sections$.subscribe(sections => {
			this.currentSection = sections.controls[this.index] as FormGroup;
		});
	}

	openPanel() {
		this.isOpen = true;
		this.selectSurveyItem.emit(this.item);
	}

	closePanel() {
		this.isOpen = false;
	}

	onSelectState(state: SURVEY_STATUS) {
		this.changeStepState.emit(state);
	}
}
