export const environment = {
	environment: 'staging',
	baseUrl: 'https://test.api.rfi.unos.org/api',
	timeout: 10000,
	colors: {
		primary: {
			light: [
				'#F26C20',
				'#D95D1F',
				'#FF7F3F',
				'#E25810',
				'#FF944D',
				'#C45312',
				'#FFB27F',
				'#AB4A14',
				'#FFCFAF',
				'#8E3E0D',
			],
			dark: [
				'#F26C20',
				'#D95D1F',
				'#FF7F3F',
				'#E25810',
				'#FF944D',
				'#C45312',
				'#FFB27F',
				'#AB4A14',
				'#FFCFAF',
				'#8E3E0D',
			],
		},
	},
	remoteConfig: {
		linkTerms: 'https://shockoe.com',
		linkPrivacy: 'https://shockoe.com',
		linkForgotPassword: 'https://shockoe.com',
	},
};
