import { Field } from 'src/app/types/fields';
import {
	CATEGORY_OPTIONS,
	CONTACT_OPTIONS,
	SUBMIT_SECTION,
	titleOptions,
} from './commons';
import { FormEntryType } from './index';
import { VALIDATIONS } from './validations';
import { generateUniqueId } from 'src/app/utils/uuid';
import { NOT_ADMIN_ROLES, SURGEON_ROLES } from 'src/app/constants/team';

export const PROGRAM_SCHEMA: Field[] = [
	{
		label: 'Program',
		placeholder: 'Select a program',
		type: 'select',
		name: 'programId',
		colSpan: 12,
		options: [],
		validate: [VALIDATIONS.REQUIRED],
	},
	{
		label: 'Select program age',
		type: 'radio',
		name: 'category',
		colSpan: 12,
		style: 'single',
		options: CATEGORY_OPTIONS,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'programId',
						justFilling: true,
					},
				],
			},
		],
	},
	{
		label: 'Appointment date  (primary roles only)',
		type: 'date',
		name: 'appointmentDate',
		colSpan: 12,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'programId',
						justFilling: true,
					},
				],
			},
		],
	},
	{
		label:
			'Note: If Appointment Date for OPTN Primary Surgeon or OPTN Primary Physician in last 36 months, attach OPTN MPSC Letter',
		type: 'file',
		name: 'optnMpscLetterAttachments',
		colSpan: 12,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'programId',
						justFilling: true,
						withoutValue: true,
					},
				],
			},
		],
	},
	{
		label: 'Number of transplants prior to joining this team (surgeon roles only)',
		type: 'number',
		name: 'careerTransplants',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'programId',
						justFilling: true,
						withoutValue: true,
					},
					{
						questionName: 'roleId',
						questionValues: SURGEON_ROLES,
						parentKey: 'role',
					},
				],
			},
			VALIDATIONS.NUMBER,
		],
	},
	{
		label: 'Number of transplants on team (surgeon roles only)',
		type: 'number',
		name: 'teamTransplants',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'programId',
						justFilling: true,
						withoutValue: true,
					},
					{
						questionName: 'roleId',
						questionValues: SURGEON_ROLES,
						parentKey: 'role',
					},
				],
			},
			VALIDATIONS.NUMBER,
		],
	},
];
const UUID = generateUniqueId();
export const ROLE_SCHEMA: Field[] = [
	{
		type: 'select',
		label:
			'Please select a team member role from the dropdown and proceed to fill in the required fields to provide information relevant to that role.',
		name: 'roleId',
		placeholder: 'Select role',
		colSpan: 12,
		options: [],
		validate: [VALIDATIONS.REQUIRED],
		roleId: UUID,
	},
	{
		label: 'Title',
		type: 'select',
		name: 'title',
		placeholder: 'Select title',
		colSpan: 4,
		options: titleOptions,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'First Name',
		type: 'text',
		name: 'firstName',
		placeholder: 'Enter first name',
		colSpan: 4,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'Last Name',
		type: 'text',
		name: 'lastName',
		placeholder: 'Enter last name',
		colSpan: 4,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'Contact phone number',
		type: 'text',
		name: 'phoneNumber',
		placeholder: 'Enter the phone number',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'Email Address',
		type: 'text',
		name: 'emailAddress',
		placeholder: 'Enter a valid email',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
			VALIDATIONS.EMAIL,
		],
		roleId: UUID,
	},
	{
		label: 'Degrees/Licenses',
		type: 'text',
		name: 'degreesLicenses',
		placeholder: 'Enter degrees/licenses',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'Board certifications/specialties',
		type: 'text',
		name: 'boardCertification',
		placeholder: 'Enter certifications/specialties',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						questionValues: NOT_ADMIN_ROLES,
					},
				],
			},
		],
		roleId: UUID,
	},
	{
		label: 'Year started in transplant',
		type: 'number',
		name: 'yearStartedInTransplant',
		placeholder: 'Enter year started in transplant',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
			VALIDATIONS.YEAR,
		],
		roleId: UUID,
	},
	{
		label:
			'Year started on team',
		type: 'number',
		name: 'yearStartedOnTeam',
		placeholder: 'Enter year started on team',
		colSpan: 6,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						justFilling: true,
					},
				],
			},
			VALIDATIONS.YEAR,
		],
		roleId: UUID,
	},
	{
		label: 'Fellowship institution',
		type: 'textarea',
		name: 'fellowshipInstitution',
		placeholder: 'Enter the Fellowship institution',
		colSpan: 12,
		validate: [
			{
				dependsOn: [
					{
						questionName: 'roleId',
						questionValues: NOT_ADMIN_ROLES,
					},
				],
			},
		],
		roleId: UUID,
	},
];
export const CONTACT_SCHEMA: Field[] = [
	{
		label: 'Role',
		name: 'type',
		type: 'select',
		placeholder: 'Select role',
		colSpan: 12,
		options: CONTACT_OPTIONS,
		validate: [VALIDATIONS.REQUIRED],
	},
	{
		label: 'Name',
		name: 'name',
		type: 'text',
		placeholder: 'Name',
	},
	{
		label: 'Email Address',
		name: 'address',
		type: 'text',
		colSpan: 6,
		placeholder: 'Email Address',
	},
	{
		label: 'Phone number',
		name: 'phoneNumber',
		type: 'tel',
		colSpan: 6,
		placeholder: 'Phone Number',
		validate: [VALIDATIONS.PHONE_NUMBER],
	},
	{
		label: 'Programs ',
		type: 'multiselect',
		options: [],
		name: 'teamContactPrograms',
		placeholder: 'Select a program or multiple programs',
	},
];
export const TEAM_SCHEMA: FormEntryType = {
	formTitle: 'Teams Survey',
	formDescription:
		'One record available per institution.  Data pre-seeded allowing user to open forms for those institutions they have authority to access and continue to input information.',
	sections: [
		{
			title: 'Primary Roles',
			fields: [
				{
					label:
						'Please provide all physicians, surgeons and transplant administrators, including at least one primary surgeon and one primary physician.',
					type: 'paragraph',
					name: '',
				},
			],
			subsectionType: 'teamMember',
			subsection: [
				{
					id: 'Team member',
					fields: ROLE_SCHEMA,
					type: 'teamMemberComponent',
					subsection: [
						{
							id: 'Program',
							parentId: '',
							fields: PROGRAM_SCHEMA,
							type: 'teamProgramComponent',
						},
					],
				},
			],
		},
		{
			title: 'Transplant Team',
			fields: [
				{
					label:
						'For each of the following roles, provide number of team members (individuals in role) as applicable',
					type: 'paragraph',
					name: '',
				},
			],
		},
		{
			title: 'Team Contacts',
			fields: [
				{
					label:
						'Please provide at least one medical records contact and one senior transplant coordinator or manager.',
					type: 'paragraph',
					name: '',
				},
			],
			subsectionType: 'teamContact',
			subsection: [
				{
					id: 'Team Contact',
					fields: CONTACT_SCHEMA,
					type: 'teamContactComponent',
				},
			],
		},
		SUBMIT_SECTION,
	],
};
